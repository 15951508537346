import { Select } from '@chakra-ui/react';

export const PeriodFilter = ({
  options,
  isLoading = false,
  period,
  setPeriod,
}) => {
  return (
    <Select
      fontWeight="bold"
      borderColor="#B3BABE"
      color="#495B66"
      variant="outline"
      borderRadius="15px"
      w="fit-content"
      value={period}
      onChange={e => setPeriod(e.target.value)}
      disabled={isLoading}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
