import { Box, Center, Spinner } from '@chakra-ui/react';
import _ from 'lodash';
import { useNews } from './hooks/useNews';
import { Filters } from './components/Filters';
import { BentoBox } from './components/BentoBox';

export const News = () => {
  const { infiniteScrollRef, isLoading, query, setQuery, data } = useNews();

  return (
    <Box h="100%" mt="20px">
      <Filters query={query} setQuery={setQuery} />
      <Box mb="10px" id="news">
        {data.map(({ id, shape, date, title, type, boxes }) => (
          <BentoBox
            key={id}
            id={id}
            shape={shape}
            date={date}
            title={title}
            type={type}
            boxes={boxes}
          />
        ))}
        {!_.isEmpty(data) > 0 && <Box ref={infiniteScrollRef} />}
        {isLoading && (
          <Center>
            <Spinner size="lg" />
          </Center>
        )}
      </Box>
    </Box>
  );
};
