import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useWidgets } from '../../WidgetsProvider';
import { Carousel } from './components/Carousel';
import { Chart, addOption } from 'components/Chart';

export const AddWidgetModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { widgets, userWidgets, updateWidgets } = useWidgets();
  const [selectedWidget, setSelectedWidget] = useState();

  const handleSelectWidget = id => {
    const widget = widgets.find(widget => widget.id === id);
    setSelectedWidget(widget);
  };

  const handleAddWidget = () => {
    updateWidgets([...userWidgets, selectedWidget]);
    onClose();
  };

  useEffect(() => {
    if (!isOpen && selectedWidget) {
      setSelectedWidget();
    }
  }, [isOpen, selectedWidget]);

  return (
    <Flex justifyContent="right">
      <Button
        leftIcon={<AddIcon color="#A5A8A9" />}
        borderColor="#B3BABE"
        color="#495B66"
        variant="outline"
        borderRadius="15px"
        onClick={onOpen}
      >
        Widget hinzufügen
      </Button>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#F1F1F3">
          <ModalHeader>Widget hinzufügen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={3} spacing={5}>
              {widgets.length > 0 &&
                widgets.map(widget => (
                  <Card
                    key={widget.id}
                    textAlign="center"
                    bg="white"
                    boxShadow="md"
                  >
                    <CardHeader p={2}>{widget.title}</CardHeader>
                    <CardBody p={2}>
                      <Box key={widget.id} h="200px">
                        <Chart option={widget} />
                      </Box>
                    </CardBody>
                    <CardFooter p={2} alignSelf="center">
                      <Button
                        size="sm"
                        borderRadius="15px"
                        colorScheme={
                          widget.id === selectedWidget?.id ? 'yellow' : 'gray'
                        }
                        onClick={() => handleSelectWidget(widget.id)}
                      >
                        auswählen
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="#37648E"
              _hover={{ bg: '#002762' }}
              _active={{
                bg: '#002762',
                transform: 'scale(0.98)',
              }}
              color="#fff"
              borderRadius="15px"
              onClick={handleAddWidget}
              isDisabled={!selectedWidget?.id}
            >
              hinzufügen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
