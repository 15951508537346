import { useEffect } from 'react';
import { useAuth } from 'routes/AuthProvider';

export const LogoutPage = () => {
  const { logout } = useAuth();

  useEffect(() => logout(), [logout]);

  return null;
};
