import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useUser } from 'routes/UserProvider';
import { fetchBenchmark } from '../services/api';

export const useBenchmarking = () => {
  const { kpiName } = useParams();
  const { user } = useUser();
  const [query, setQuery] = useState({
    period: 'monat',
    date: null,
  });
  const [data, setData] = useState(null);
  const [enabledDates, setEnabledDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const buildBenchmarkId = () => {
      const { company } = user;
      const { period, date } = query;
      const dateFormatted = date?.replaceAll('/', '');

      return date ? `${company?.rootFolder}-${dateFormatted}-${period}` : null;
    };

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const id = buildBenchmarkId();
        const { data } = await fetchBenchmark(id, kpiName);
        if (!_.isEmpty(data)) {
          setData(data?.benchmark);
          setEnabledDates(data?.enabledDates);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.company) {
      fetchData();
    }
  }, [user, query, kpiName]);

  return { setQuery, isLoading, data, enabledDates, kpiName };
};
