import { Routes, Route, Navigate } from 'react-router-dom';
import { PublicLayout } from 'routes/PublicLayout';
import { ProtectedLayout } from 'routes/ProtectedLayout';
import { LoginPage } from 'pages/LoginPage';
import { ChangePasswordPage } from 'pages/ChangePasswordPage';
// import { RecoverPasswordPage } from 'pages/RecoverPasswordPage';
// import { RegisterPage } from 'pages/RegisterPage';
import { NewsPage } from 'pages/NewsPage';
import { RevenuesPage } from 'pages/RevenuesPage';
import { ExpendituresPage } from 'pages/ExpendituresPage';
import { BenchmarkingPage } from 'pages/BenchmarkingPage';
import { CashierPage } from 'pages/CashierPage';
import { PickupsPage } from 'pages/PickupsPage';
import { SettingsPage } from 'pages/SettingsPage';
import { LogoutPage } from 'pages/LogoutPage';

function App() {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/recover-password" element={<RecoverPasswordPage />} /> */}
        <Route
          path="/change-password/:token"
          element={<ChangePasswordPage />}
        />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
      </Route>
      <Route path="/" element={<ProtectedLayout />}>
        <Route path="/" element={<Navigate to="/nachrichten" />} />
        <Route path="/nachrichten" element={<NewsPage />} />
        <Route path="/finanzen">
          <Route
            path="/finanzen"
            element={<Navigate to="/finanzen/einnahmen" />}
          />
          <Route path="einnahmen" element={<RevenuesPage />} />
          <Route path="ausgaben" element={<ExpendituresPage />} />
        </Route>
        <Route path="/benchmarking" element={<BenchmarkingPage />} />
        <Route path="/cashier" element={<CashierPage />} />
        <Route path="/benchmarking/:kpiName" element={<BenchmarkingPage />} />
        <Route path="/pickups" element={<PickupsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/logout" element={<LogoutPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
