import { Flex, Box, Text, Icon } from '@chakra-ui/react';
import { BsArrowDownRight, BsArrowUpRight, BsArrowRight } from 'react-icons/bs';

export const trendValue = ({ value, arrow }) => {
  let valueColor = 'black';

  if (value.includes('+')) {
    valueColor = 'green.400';
  }

  if (value.includes('-')) {
    valueColor = 'red.400';
  }

  const arrows = {
    '-1': <Icon as={BsArrowDownRight} boxSize={8} />,
    0: <Icon as={BsArrowRight} boxSize={8} />,
    1: <Icon as={BsArrowUpRight} boxSize={8} />,
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Box>
        <Text fontSize="xl" color={valueColor}>
          {value}
        </Text>
      </Box>
      <Box ml="10px">{arrows[arrow]}</Box>
    </Flex>
  );
};
