// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'routes/AuthProvider';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import enMessages from './i18n/en.json';
import deMessages from './i18n/de.json';

const messages = {
  en: enMessages,
  de: deMessages,
};

const language = 'de';

const root = createRoot(document.getElementById('root'));
root.render(
  // <StrictMode>
  <IntlProvider locale={language} messages={messages[language]}>
    <BrowserRouter>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </AuthProvider>
    </BrowserRouter>
  </IntlProvider>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
