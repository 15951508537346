import { Fragment } from 'react';
import { Tr, Td, Text, Divider, Flex } from '@chakra-ui/react';

const formatValueToCurrency = value =>
  value.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const showWithColor = value => {
  if (!value && value !== 0) {
    return null;
  }

  let valueColor = 'black';
  let stringValue = value.toString();

  if (value < 0) {
    valueColor = 'red.400';
    stringValue = `${stringValue}%`;
  } else if (value > 0) {
    valueColor = 'green.400';
    stringValue = `+${stringValue}%`;
  } else {
    stringValue = `${stringValue}%`;
  }

  return (
    <Text as="span" color={valueColor} fontSize="12px">
      {stringValue}
    </Text>
  );
};

const isObject = value =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

const formatToCurrencyWithPct = ({ value, yoyGrowthPct }) => {
  return (
    <Flex flexDir="column">
      {formatValueToCurrency(value)}
      {showWithColor(yoyGrowthPct)}
    </Flex>
  );
};

const formatToCurrency = value => formatValueToCurrency(value);

const PickupsTableRow = ({
  id,
  name,
  pickups,
  pickupsLastYear,
  roomNights,
  roomNightsLastYear,
  sales,
  salesLastYear,
  ttc,
  ttcLastYear,
  cancellations,
  cancellationsLastYear,
  index,
}) => {
  return (
    <Fragment key={id}>
      <Tr key={id}>
        <Td>
          <Text fontWeight="bold">{name}</Text>
        </Td>
        <Td isNumeric>
          {isObject
            ? formatToCurrencyWithPct(pickups)
            : formatToCurrency(pickups)}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {formatToCurrency(pickupsLastYear)}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {roomNights}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {roomNightsLastYear}
        </Td>
        <Td isNumeric>
          {isObject ? formatToCurrencyWithPct(sales) : formatToCurrency(sales)}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {formatToCurrency(salesLastYear)}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {ttc.toLocaleString('de-DE', {
            maximumFractionDigits: 2,
          })}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {ttcLastYear.toLocaleString('de-DE', {
            maximumFractionDigits: 2,
          })}
        </Td>
        <Td isNumeric>
          {isObject
            ? formatToCurrencyWithPct(cancellations)
            : formatToCurrency(pickups)}
        </Td>
        <Td isNumeric alignContent="flex-start">
          {formatToCurrency(cancellationsLastYear)}
        </Td>
      </Tr>
      {index === 0 && (
        <Tr>
          <Td colSpan="11" textAlign="center" py={6}>
            <Divider borderColor="whispersSecondary.500" />
          </Td>
        </Tr>
      )}
    </Fragment>
  );
};

export { PickupsTableRow };
