import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_BASE_URL
    : process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(({ headers, ...config }) => {
  const commonHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };

  if (config.url === '/auth/login') {
    return {
      ...config,
      headers: commonHeaders,
    };
  }

  const token = headers.Authorization || localStorage.getItem('token');

  if (token) {
    commonHeaders.Authorization = token;
  }

  return {
    ...config,
    headers: commonHeaders,
  };
});

export const get = axiosInstance.get;
export const post = axiosInstance.post;
export const put = axiosInstance.put;
export const remove = axiosInstance.delete;
export const patch = axiosInstance.patch;
export default axiosInstance;
