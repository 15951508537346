import { Box, Text } from '@chakra-ui/react';

export const expectedValue = ({ value, color }) => {
  const colors = {
    '-1': 'red.400',
    0: 'black',
    1: 'green.400',
  };

  return (
    <Box w="100%">
      <Text color={colors[color]} fontWeight="bold">
        {value}
      </Text>
    </Box>
  );
};
