import { Navigate, useOutlet } from 'react-router-dom';
import { Flex, Box, Image } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useAuth } from './AuthProvider';
import Screen from 'images/screen.png';
import Logo from 'images/logo.png';

export const PublicLayout = () => {
  const { auth } = useAuth();
  const outlet = useOutlet();

  if (!isEmpty(auth)) {
    return <Navigate to="/nachrichten" replace />;
  }

  return (
    <Flex
      flexDirection="column"
      height="100vh"
      backgroundColor="#fff"
      backgroundImage={`url(${Screen})`}
      backgroundPosition="center center"
      backgroundSize={{ base: 'cover', md: 'auto', '2xl': '100%' }}
      backgroundRepeat="no-repeat"
    >
      <Box position="absolute" w={{ base: '100%', md: 'auto' }} p="20px">
        <Image src={Logo} alt="Logo Whispers" maxH="48px" margin="0 auto" />
      </Box>
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="20px"
      >
        {outlet}
      </Flex>
    </Flex>
  );
};
