import { padTo2Digits } from './padTo2Digits';

export const formatDateParam = date => {
  const dateNew = new Date(date);

  const day = padTo2Digits(dateNew.getDate());
  const month = padTo2Digits(dateNew.getMonth() + 1);
  const year = dateNew.getFullYear();

  return `${year}/${month}/${day}`;
};

export const formatDateMonthYearParam = date => {
  const dateNew = new Date(date);

  const month = padTo2Digits(dateNew.getMonth() + 1);
  const year = dateNew.getFullYear();

  return `${year}/${month}`;
};

export const previousDayParam = date => {
  const dateNew = new Date(date);
  const previous = new Date(dateNew.getTime());
  previous.setDate(dateNew.getDate() - 1);

  return formatDateParam(previous);
};

export const previousFiveDaysParam = date => {
  const dateNew = new Date(date);
  const previous = new Date(dateNew.getTime());
  previous.setDate(dateNew.getDate() - 5);

  return formatDateParam(previous);
};

export const dateBox = date => {
  const months = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ];
  const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  const dateNew = new Date(date);
  const numberDay = dateNew.getDay();
  const nameDay = days[numberDay];
  let formatted_date =
    nameDay +
    ',' +
    ' ' +
    dateNew.getDate() +
    ' ' +
    months[date.getMonth()] +
    ' ' +
    date.getFullYear();
  return formatted_date;
};
