import { useWidgets } from '../../WidgetsProvider';
import { Draggable } from './Draggable';
import { reorder } from './utils/reorder';

export const DraggableContainer = () => {
  const { userWidgets, updateWidgets } = useWidgets();

  const onCollapseWidget = _id => {
    updateWidgets(
      userWidgets.map(item => {
        if (item._id === _id) {
          return {
            ...item,
            collapse: !item.collapse,
          };
        } else {
          return item;
        }
      })
    );
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      userWidgets,
      result.source.index,
      result.destination.index
    );

    updateWidgets(items);
  };

  const onRemoveWidget = _id => {
    updateWidgets(userWidgets.filter(item => item._id !== _id));
  };

  return (
    <Draggable
      onCollapseWidget={onCollapseWidget}
      onDragEnd={onDragEnd}
      onRemoveWidget={onRemoveWidget}
      userWidgets={userWidgets}
    />
  );
};
