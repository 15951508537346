import { Flex, HStack } from '@chakra-ui/react';
import { PeriodFilter } from './components/PeriodFilter';
import { DateFilter } from './components/DateFilter';

export const Filters = ({
  data,
  enabledDates,
  isExpendituresView,
  isLoading,
  setData,
  query,
}) => {
  const setPeriod = period => setData(prevData => ({ ...prevData, period }));

  const setDate = date => setData(prevData => ({ ...prevData, date }));

  return (
    <Flex mb="20px" justifyContent="right">
      <HStack spacing="5px">
        <PeriodFilter
          isExpendituresView={isExpendituresView}
          isLoading={isLoading}
          period={query.period}
          setPeriod={setPeriod}
        />
        <DateFilter
          date={query.date}
          enabledDates={enabledDates}
          isExpendituresView={isExpendituresView}
          isLoading={isLoading}
          setDate={setDate}
        />
      </HStack>
    </Flex>
  );
};
