import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useAuth } from 'routes/AuthProvider';
import { changePassword } from '../services/api';

export const useChangePasswordPage = () => {
  const { token } = useParams();
  const { login } = useAuth();
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [visibility, setVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const [validation, setValidation] = useState({
    password: false,
    confirmPassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const loginTimeoutRef = useRef();
  const [alertMessage, setAlertMessage] = useState({
    status: '',
    code: '',
  });

  const onChangeInput = e => {
    const { name, value } = e.target;
    setValues(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await changePassword(values, token);
      if (data?.token && typeof data?.token === 'string') {
        setAlertMessage({
          status: 'success',
          code: data.code,
        });

        loginTimeoutRef.current = setTimeout(() => login(data.token), 3000);
      }
    } catch (error) {
      const { code } = error.response?.data;
      setAlertMessage({
        status: 'error',
        code,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (values.confirmPassword !== '') {
      if (values.confirmPassword === values.password) {
        setValidation(prev => ({ ...prev, confirmPassword: true }));
      } else {
        setValidation(prev => ({ ...prev, confirmPassword: false }));
      }
    }

    return () => {
      if (loginTimeoutRef.current) {
        clearTimeout(loginTimeoutRef.current);
      }
    };
  }, [values]);

  useEffect(() => {
    try {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp < Date.now() / 1000) {
        const error = new Error();
        error.code = 'EXPIRED_TOKEN';
        throw error;
      }

      if (!decodedToken?.isOTT) {
        const error = new Error();
        error.code = 'INVALID_TOKEN';
        throw error;
      }
    } catch (error) {
      setAlertMessage({
        status: 'error',
        code: error.code,
      });
    } finally {
      setIsLoadingView(false);
    }
  }, [token]);

  return {
    alertMessage,
    isLoading,
    isLoadingView,
    handleSubmit,
    onChangeInput,
    values,
    validation,
    visibility,
    setValidation,
    setVisibility,
  };
};
