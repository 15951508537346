import { FormattedMessage } from 'react-intl';
import { Stack, Heading, Icon, Spinner, Center } from '@chakra-ui/react';
import { FaLock } from 'react-icons/fa';
import { useChangePasswordPage } from './hooks/useChangePasswordPage';
import { StatusAlert } from './components/StatusAlert';
import { ChangePasswordForm } from './components/ChangePasswordForm';

export const ChangePasswordPage = () => {
  const {
    alertMessage,
    isLoading,
    isLoadingView,
    handleSubmit,
    onChangeInput,
    values,
    validation,
    visibility,
    setValidation,
    setVisibility,
  } = useChangePasswordPage();

  return (
    <Stack
      bg="rgba(250, 250, 250, 0.9)"
      borderRadius="15px"
      flexDir="column"
      mb="2"
      textAlign="center"
      padding="30px 40px"
      shadow="2xl"
      w={['100%', 'md', 'md', 'md', 'lg', 'xl']}
    >
      <Heading as="h1" fontSize={['lg', 'xl', '2xl', '3xl', '4xl']}>
        <Icon as={FaLock} boxSize={12} />
      </Heading>
      <Heading
        as="h2"
        fontSize={{ base: 'xs', lg: 'sm' }}
        letterSpacing={3}
        fontWeight="900"
        color="whispersPrimary.600"
        textTransform="uppercase"
      >
        <FormattedMessage id="CHANGE_PASSWORD_PAGE_HEADING" />
      </Heading>
      {isLoadingView && (
        <Center h="xs">
          <Spinner size="lg" />
        </Center>
      )}
      {!isLoadingView && alertMessage.status && (
        <StatusAlert status={alertMessage.status} code={alertMessage.code} />
      )}
      {!isLoadingView && !alertMessage.status && (
        <ChangePasswordForm
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          onChangeInput={onChangeInput}
          values={values}
          validation={validation}
          visibility={visibility}
          setValidation={setValidation}
          setVisibility={setVisibility}
        />
      )}
    </Stack>
  );
};
