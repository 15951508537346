import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { PickupsTableRow } from './components/PickupsTableRow';

const lastYearTitle = (
  <Text as="span" fontSize={12}>
    (LY)
  </Text>
);

export const PickupsTable = ({ isLoading, pickups }) => {
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="whispersSecondary" size="lg">
        <Thead bgColor="whispersSecondary.500">
          <Tr>
            <Th color="white">
              <Text as="span">channel</Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                pickups
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                pickups {lastYearTitle}
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                room nights
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                room nights {lastYearTitle}
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                sales
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                sales {lastYearTitle}
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                ttc
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                ttc {lastYearTitle}
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                cancellations
              </Text>
            </Th>
            <Th isNumeric color="white" colSpan={1} textAlign="center">
              <Text as="span" ml="5px">
                cancellations {lastYearTitle}
              </Text>
            </Th>
          </Tr>
        </Thead>
        {isLoading ? (
          <Tbody h="300px">
            <Tr>
              <Td colSpan="11">
                <Center>
                  <Spinner size="lg" />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        ) : pickups?.length > 0 ? (
          <Tbody>
            <Tr></Tr>
            <Tr>
              <Td colSpan="11" py={2}></Td>
            </Tr>
            {pickups.map((pickup, index) => (
              <PickupsTableRow key={pickup.name} {...pickup} index={index} />
            ))}
          </Tbody>
        ) : (
          <Tbody h="300px">
            <Tr>
              <Td colSpan="11">
                <Center>
                  <Text>No data found</Text>
                </Center>
              </Td>
            </Tr>
          </Tbody>
        )}
      </Table>
    </TableContainer>
  );
};
