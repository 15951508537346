import { Select } from '@chakra-ui/react';

export const PeriodFilter = ({
  isExpendituresView,
  isLoading,
  period,
  setPeriod,
}) => {
  return (
    <Select
      fontWeight="bold"
      borderColor="#B3BABE"
      color="#495B66"
      variant="outline"
      borderRadius="15px"
      w="fit-content"
      value={period}
      onChange={e => setPeriod(e.target.value)}
      disabled={isLoading || !period}
    >
      {!isExpendituresView && <option value="TAG">TAG</option>}
      <option value="MONAT">MONAT</option>
      <option value="YTD">YTD</option>
    </Select>
  );
};
