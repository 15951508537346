import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export const Lightbox = ({ url }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex ml="5px" mt="2px">
      <InfoOutlineIcon onClick={onOpen} cursor="pointer" />
      <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image src={url} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
