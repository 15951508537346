import { post } from 'helpers/axios';

export const changePassword = ({ password, confirmPassword }, token) =>
  post(
    '/auth/change-password',
    { password, confirmPassword },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
