import { FormattedMessage, useIntl } from 'react-intl';
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Button,
  FormHelperText,
} from '@chakra-ui/react';
import { PasswordInput } from './components/PasswordInput';
import { PasswordStrength } from './components/PasswordStrength';

export const ChangePasswordForm = ({
  isLoading,
  handleSubmit,
  onChangeInput,
  values,
  validation,
  visibility,
  setValidation,
  setVisibility,
}) => {
  const { formatMessage } = useIntl();

  const handlePasswordVisibility = name => {
    setVisibility({
      ...visibility,
      [name]: !visibility[name],
    });
  };

  return (
    <form>
      <Stack pt="20px" spacing={4}>
        <FormControl>
          <FormLabel>
            <FormattedMessage id="CHANGE_PASSWORD_PAGE_FORM_NEW_PASSWORD_LABEL" />
          </FormLabel>
          <PasswordInput
            isVisible={visibility.password}
            name="password"
            onChangeInput={onChangeInput}
            placeholder={formatMessage({
              id: 'CHANGE_PASSWORD_PAGE_FORM_NEW_PASSWORD_PLACEHOLDER',
            })}
            setIsVisible={() => handlePasswordVisibility('password')}
            value={values.password}
          />
          <FormHelperText>
            <PasswordStrength
              password={values.password}
              onValidityChange={isValid =>
                setValidation({
                  ...validation,
                  password: isValid,
                })
              }
            />
          </FormHelperText>
        </FormControl>
        <FormControl
          isInvalid={
            values.confirmPassword !== '' && !validation.confirmPassword
          }
        >
          <FormLabel>
            <FormattedMessage id="CHANGE_PASSWORD_PAGE_FORM_CONFIRM_PASSWORD_LABEL" />
          </FormLabel>
          <PasswordInput
            isVisible={visibility.confirmPassword}
            name="confirmPassword"
            onChangeInput={onChangeInput}
            placeholder={formatMessage({
              id: 'CHANGE_PASSWORD_PAGE_FORM_CONFIRM_PASSWORD_PLACEHOLDER',
            })}
            setIsVisible={() => handlePasswordVisibility('confirmPassword')}
            value={values.confirmPassword}
          />
          {values.confirmPassword !== '' && !validation.confirmPassword && (
            <FormErrorMessage>
              <FormattedMessage id="CHANGE_PASSWORD_PAGE_FORM_CONFIRM_PASSWORD_ERROR" />
            </FormErrorMessage>
          )}
        </FormControl>
        <Box textAlign="center">
          <Button
            size="lg"
            type="submit"
            colorScheme="whispersPrimary"
            borderRadius="15px"
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText={formatMessage({
              id: 'CHANGE_PASSWORD_PAGE_FORM_SUBMIT_BUTTON_LOADING',
            })}
            isDisabled={
              !values.password ||
              !values.confirmPassword ||
              !validation.password ||
              !validation.confirmPassword
            }
          >
            <FormattedMessage id="CHANGE_PASSWORD_PAGE_FORM_SUBMIT_BUTTON" />
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
