import { VStack } from '@chakra-ui/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DraggableItem } from './components/DraggableItem';

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#ededed' : '',
});

export const Draggable = ({
  onCollapseWidget,
  onDragEnd,
  onRemoveWidget,
  userWidgets,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <VStack
            w="100%"
            /**
             * 100vh - 170px
             * 100vh = 100% of the viewport height
             *  170px = 100vh - (header height + add widget button height + margin top + margin bottom)
             */
            h="calc(100vh - 170px)"
            mt="20px"
            spacing="16px"
            pb="10px"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            overflowY="auto"
          >
            {userWidgets.map((widget, index) => (
              <DraggableItem
                key={widget._id}
                index={index}
                onCollapseWidget={onCollapseWidget}
                onRemoveWidget={onRemoveWidget}
                widget={widget}
              />
            ))}
            {provided.placeholder}
          </VStack>
        )}
      </Droppable>
    </DragDropContext>
  );
};
