import { addYAxisLabelFormatter } from './addYAxisLabelFormatter';

const formatValueToCurrency = value =>
  value.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const addOption = (widget, showSeriesLabel = true) => {
  const { type, series, configuration } = widget;

  let option = {
    grid: {
      left: 10,
      right: 10,
      bottom: 20,
      top: 55,
      containLabel: true,
    },
    legend: {},
    tooltip: {
      axisPointer: {
        type: 'shadow',
        label: {
          show: true,
        },
      },
      valueFormatter: value => value.toLocaleString('de-DE'),
    },
    axisPointer: {},
  };

  switch (type) {
    case 'bar':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'bar',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
        ],
      };
      break;
    case 'bar-bar':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'bar',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
          {
            type: 'bar',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
        ],
      };
      break;
    case 'bar-bar-with-datazoom':
      option = {
        ...option,
        grid: {
          left: 0,
          right: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          valueFormatter: value => formatValueToCurrency(value),
        },
        xAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLabel: {
            formatter: value => {
              const date = value.split('/');
              return `${date[1]}/${date[2]}`;
            },
          },
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            start: 100,
            end: 90,
            right: 5,
          },
        ],
        series: [
          {
            type: 'bar',
            // label: {
            //   show: showSeriesLabel,
            //   position: 'top',
            //   formatter: ({ data }) => data.toLocaleString('de-DE'),
            // },
            label: {
              show: false,
            },
            emphasis: {
              focus: 'series',
            },
            ...series[0],
          },
          {
            type: 'bar',
            label: {
              show: false,
            },
            emphasis: {
              focus: 'series',
            },
            ...series[1],
          },
        ],
      };
      break;
    case 'bar-bar-bar':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'bar',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
          {
            type: 'bar',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
          {
            type: 'bar',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[2],
          },
        ],
      };
      break;
    case 'gauge':
      option = {
        ...option,
        // xAxis: {
        //   // type: 'category',
        //   ...configuration.xAxis,
        // },
        // yAxis: {
        //   // type: 'value',
        //   ...configuration.yAxis,
        // },
        series: [
          {
            type: 'gauge',
            anchor: {
              show: true,
              showAbove: true,
              size: 18,
              itemStyle: {
                color: '#FAC858',
              },
            },
            pointer: {
              icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
              width: 8,
              length: '80%',
              offsetCenter: [0, '8%'],
            },
            progress: {
              show: true,
              overlap: true,
              roundCap: true,
            },
            axisLine: {
              roundCap: true,
            },
            data: [
              {
                title: {
                  offsetCenter: ['-40%', '80%'],
                },
                detail: {
                  offsetCenter: ['-40%', '95%'],
                },
                ...series[0].data[0],
              },
              {
                title: {
                  offsetCenter: ['+40%', '80%'],
                },
                detail: {
                  offsetCenter: ['+40%', '95%'],
                },
                ...series[0].data[1],
              },
            ],
            title: {
              fontSize: 14,
            },
            detail: {
              width: 40,
              height: 14,
              fontSize: 14,
              color: '#fff',
              backgroundColor: 'inherit',
              borderRadius: 3,
              formatter: '{value}%',
            },
          },
        ],
      };
      break;
    case 'waterfall':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'bar',
            stack: 'Total',
            silent: true,
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent',
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
              },
            },
            ...series[0],
          },
          {
            type: 'bar',
            stack: 'Total',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
          {
            type: 'bar',
            stack: 'Total',
            label: {
              show: showSeriesLabel,
              position: 'bottom',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[2],
          },
          {
            type: 'bar',
            stack: 'Total',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[3],
          },
        ],
      };
      break;
    case 'finance-waterfalls':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          show: false,
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          axisLabel: {
            show: false,
          },
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'bar',
            stack: 'Total',
            silent: true,
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent',
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
              },
            },
            ...series[0],
          },
          {
            type: 'bar',
            stack: 'Total',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
          {
            type: 'bar',
            stack: 'Total',
            label: {
              show: showSeriesLabel,
              position: 'bottom',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[2],
          },
          {
            type: 'bar',
            stack: 'Total',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[3],
          },
        ],
      };
      break;
    case 'line':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'line',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
        ],
      };
      break;
    case 'line-line':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'line',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
          {
            type: 'line',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
        ],
      };
      break;
    case 'line-line-line':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          ...configuration.xAxis,
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          ...addYAxisLabelFormatter,
          ...configuration.yAxis,
        },
        series: [
          {
            type: 'line',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
          {
            type: 'line',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
          {
            type: 'line',
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[2],
          },
        ],
      };
      break;
    case 'line-bar':
      option = {
        ...option,
        xAxis: {
          type: 'category',
          nameLocation: 'center',
          nameGap: 25,
          ...configuration.xAxis,
        },
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              align: 'left',
            },
            ...addYAxisLabelFormatter,
            ...configuration.yAxis[0],
          },
          {
            type: 'value',
            nameTextStyle: {
              align: 'right',
            },
            ...addYAxisLabelFormatter,
            ...configuration.yAxis[1],
          },
        ],
        series: [
          {
            type: 'line',
            yAxisIndex: 0,
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[0],
          },
          {
            type: 'bar',
            yAxisIndex: 1,
            label: {
              show: showSeriesLabel,
              position: 'top',
              formatter: ({ data }) => data.toLocaleString('de-DE'),
            },
            ...series[1],
          },
        ],
      };
      break;
    default:
      console.log("type doesn't exist");
      break;
  }

  return option;
};
