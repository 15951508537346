import { useRef, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { init, getInstanceByDom, registerTheme } from 'echarts';
import { theme, addOption } from './utils';

registerTheme('whispers', theme);

export const Chart = ({
  loading,
  option,
  optionV2 = null,
  settings,
  style,
  theme = 'whispers',
  showSeriesLabel,
  onAnimationComplete,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Initialize echarts instance
    let chart;

    if (chartRef.current) {
      chart = init(chartRef.current, theme, {
        locale: 'ZH',
        width: 'auto',
        height: 'auto',
      });
    }

    // Add chart resize listener
    // ResizeObserver is a new API that allows you to listen to changes in the size of an element
    const resizeChart = () => {
      chart?.resize();
    };
    window.addEventListener('resize', resizeChart);

    // Clean up
    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (option) {
        const customOption = addOption(option, showSeriesLabel);
        chart.setOption(customOption, settings);
      } else {
        chart.setOption(optionV2, settings);
      }
    }
  }, [option, settings, showSeriesLabel, theme, optionV2]); // We need to update the chart options, settings and theme when they change

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (loading) {
        chart.showLoading();
      } else {
        chart.hideLoading();
      }
    }
  }, [loading, theme]);

  useEffect(() => {
    if (chartRef.current !== null && onAnimationComplete) {
      const chart = getInstanceByDom(chartRef.current);
      chart.resize();
    }
  }, [onAnimationComplete]);

  return (
    <Box
      ref={chartRef}
      w="100%"
      h="100%"
      style={{
        ...style,
      }}
    />
  );
};
