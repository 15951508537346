import { useEffect, useState } from 'react';
import { fetchWidgets } from '../services/api';

export const useFetchWidgets = () => {
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchWidgets();
        if (data) {
          setWidgets(data.widgets);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return { widgets };
};
