import { Flex, Box, HStack, Text, IconButton, Select } from '@chakra-ui/react';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

// const years = range(1990, getYear(new Date()) + 1, 1);
const years = _.range(2017, new Date().getFullYear() + 1);
const months = Array.from({ length: 12 }, (item, i) => {
  return new Date(0, i).toLocaleString('de-DE', { month: 'long' });
});

/**
 * changeMonth: ƒ (e)
 * changeYear: ƒ (e)
 * customHeaderCount: 2
 * date: Fri May 19 2023 20:48:57 GMT+0200 (hora de verano de Europa central) {}
 * decreaseMonth: ƒ ()
 * decreaseYear: ƒ ()
 * increaseMonth: ƒ ()
 * increaseYear: ƒ ()
 * monthContainer: null
 * monthDate: Fri May 19 2023 20:48:57 GMT+0200 (hora de verano de Europa central) {}
 * nextMonthButtonDisabled: false
 * nextYearButtonDisabled: false
 * prevMonthButtonDisabled: false
 * prevYearButtonDisabled: false
 * selectingDate: null
 */
export const CustomHeader = ({
  changeMonth,
  changeYear,
  date,
  decreaseMonth,
  increaseMonth,
  monthDate,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <Box>
      <Flex alignItems="center" h="32px">
        <Box w="40px" textAlign="right">
          <IconButton
            aria-label="Left month"
            variant="outline"
            colorScheme="gray"
            color="gray"
            icon={<ChevronLeftIcon />}
            borderRadius="50%"
            fontSize="30px"
            size="sm"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          />
        </Box>
        <Box flex="1" textAlign="center">
          <Text fontWeight="bold">
            {monthDate.toLocaleString('de-DE', {
              month: 'long',
              year: 'numeric',
            })}
          </Text>
        </Box>
        <Box w="40px" textAlign="left">
          <IconButton
            aria-label="Right month"
            variant="outline"
            colorScheme="gray"
            color="gray"
            icon={<ChevronRightIcon />}
            borderRadius="50%"
            fontSize="30px"
            size="sm"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
        </Box>
      </Flex>
      <Box h="24px" my="5px">
        <HStack justifyContent="center" spacing="5px" height="100%">
          <Select
            placeholder="Month"
            w="fit-content"
            size="xs"
            borderRadius="30px"
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Year"
            w="fit-content"
            size="xs"
            borderRadius="30px"
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    </Box>
  );
};
