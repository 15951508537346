import { createContext, useContext, useCallback, useMemo } from 'react';
import { useUserHook } from './hooks/useUserHook';

const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  const { user, updateUserProfileWidgets } = context;

  return { user, updateUserProfileWidgets };
};

export const UserProvider = ({ children }) => {
  const [user, updateUser] = useUserHook();

  const updateUserProfileWidgets = useCallback(
    async widgets =>
      updateUser({
        ...user,
        profile: { ...user.profile, widgets },
      }),
    [updateUser, user]
  );

  const value = useMemo(
    () => ({ user, updateUserProfileWidgets }),
    [user, updateUserProfileWidgets]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
