import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  fonts: {
    body: 'Lato, sans-serif',
    heading: 'Lato, serif',
    mono: 'Lato, monospace',
  },
  styles: {
    global: {
      '*': {
        margin: 0,
        padding: 0,
      },
      body: {
        backgroundColor: '#F1F1F3',
      },
    },
  },
  colors: {
    whispersPrimary: {
      50: '#e1f8f8',
      100: '#c9e4e2',
      200: '#accfcf',
      300: '#8fbbba',
      400: '#71a7a6',
      500: '#588e8d',
      600: '#426e6e',
      700: '#2d504f',
      800: '#163030',
      900: '#001313',
    },
    whispersSecondary: {
      50: '#f7effb',
      100: '#dcd4e0',
      200: '#c3b9c7',
      300: '#aa9daf',
      400: '#908097',
      500: '#78677e',
      600: '#5d5062',
      700: '#423947',
      800: '#29212c',
      900: '#110914',
    },
    whispersTertiary: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    },
  },
  components: {
    // Heading: {
    //   baseStyle: {
    //     fontFamily: 'Lato',
    //   },
    // },
    // Text: {
    //   baseStyle: {
    //     fontFamily: 'Nunito',
    //     color: '#2D3748',
    //   },
    // },
    Button: {
      variants: {
        'filter-revenues': {
          bg: '#85A6A6',
          color: 'white',
          borderRadius: '20px',
          p: '0 15px',
          h: '20px',
          _hover: {
            bg: '#879297',
          },
          _active: {
            bg: '#416A69',
          },
        },
        'filter-expenditures': {
          bg: '#C9783D',
          color: 'white',
          borderRadius: '20px',
          p: '0 15px',
          h: '20px',
          _hover: {
            bg: '#879297',
          },
          _active: {
            bg: '#994508',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: '#37648E',
            borderColor: '#37648E',
          },
        },
      },
    },
    Switch: {
      variants: {
        'switch-revenues': {
          track: {
            _checked: {
              bg: '#85a6a6',
            },
          },
        },
        'switch-expenditures': {
          track: {
            _checked: {
              bg: '#c9783d',
            },
          },
        },
      },
    },
    // Input: {
    //   baseStyle: {
    //     field: {
    //       borderRadius: '15px',
    //       bg: '#fff',
    //     },
    //   },
    //   parts: {
    //     field: {
    //       borderRadius: '15px',
    //       bg: '#fff',
    //     },
    //   },
    // },
  },

  // components: {
  //   CustomBox: {
  //     baseStyle: {
  //       borderWidth: 5,
  //       borderColor: 'purple',
  //       width: 200,
  //       height: 200,
  //     },
  //     variants: {
  //       light: {
  //         borderColor: 'purple.200',
  //       },
  //     },
  //   },
  // },
});
