import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Flex,
  Box,
  Center,
  Spinner,
  Card,
  CardBody,
  Text,
  Icon,
  HStack,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useBenchmarking } from './hooks/useBenchmarking';
import { Filters } from './components/Filters';
import { trendValue } from './utils/trendValue';
import { benchmarkValue } from './utils/benchmarkValue';
import { expectedValue } from './utils/expectedValue';

const drawTitle = type => (
  <Flex w="100%" justifyContent="center" alignItems="center" px={6} py={3}>
    <Heading size="sm" color="gray.600">
      <FormattedMessage id={`benchmarking.table.th.${type}`} />
    </Heading>
    <Tooltip
      label={
        <FormattedMessage
          id={`benchmarking.table.th.${type}.tooltip`}
          values={{ br: <br /> }}
        />
      }
    >
      <InfoOutlineIcon
        boxSize="12px"
        ml="5px"
        cursor="pointer"
        color="#59908f"
      />
    </Tooltip>
  </Flex>
);

const calculateAccordionDefaultIndex = (data, kpiName) => {
  if (!data) return [];

  const defaultIndices = data.rows
    .map((row, index) =>
      kpiName && row.kpis.some(kpi => kpi.name === kpiName) ? index : -1
    )
    .filter(index => index !== -1);

  return defaultIndices.length
    ? defaultIndices
    : [...Array(data.rows.length).keys()];
};

const CircleIcon = props => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

const getTrafficLight = trafficLight => {
  const colors = {
    '-2': 'red.400',
    0: 'yellow.400',
    2: 'green.400',
  };

  return <CircleIcon color={colors[trafficLight]} boxSize={8} />;
};

export const Benchmarking = () => {
  const { setQuery, isLoading, data, enabledDates, kpiName } =
    useBenchmarking();

  return (
    <Box h="100%" mt="20px" mr="20px">
      <Filters
        data={data}
        enabledDates={enabledDates}
        isLoading={isLoading}
        setData={setQuery}
      />
      <Flex direction="column" w="100%" mt="20px" mb="10px" textAlign="center">
        <HStack
          spacing="10px"
          textTransform="uppercase"
          mb="20px"
          bg="gray.200"
        >
          <Box minW="250px" w="100%" h="100%"></Box>
          {drawTitle('traffic_light')}
          {drawTitle('trend')}
          <Flex
            w="100%"
            justifyContent="center"
            alignItems="center"
            px={6}
            py={3}
          >
            <Heading size="sm" color="gray.600">
              <FormattedMessage id="benchmarking.table.th.benchmarking" />
            </Heading>
          </Flex>
          {drawTitle('expected_next_month')}
        </HStack>
        {isLoading ? (
          <Center my="50px">
            <Spinner size="lg" />
          </Center>
        ) : data ? (
          <Accordion
            defaultIndex={calculateAccordionDefaultIndex(data, kpiName)}
            allowMultiple
          >
            {data?.rows.map(({ name, kpis }, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text textTransform="uppercase">{name}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel p={0}>
                  {kpis.map(
                    (
                      { name, trafficLight, trend, benchmark, expected },
                      index
                    ) => {
                      const kpiActive = kpiName === name;
                      const bg = kpiActive ? '#dcebeb' : 'transparent';
                      return (
                        <Fragment key={index}>
                          <HStack
                            spacing="10px"
                            textAlign="center"
                            p="10px"
                            my="10px"
                            rounded="2xl"
                            bg={bg}
                          >
                            <Box minW="250px" w="100%" h="100%">
                              <Card rounded="2xl">
                                <CardBody>
                                  <Text textAlign="center">{name}</Text>
                                </CardBody>
                              </Card>
                            </Box>
                            <Box w="100%">{getTrafficLight(trafficLight)}</Box>
                            <Box w="100%">{trendValue(trend)}</Box>
                            <Box w="100%">{benchmarkValue(benchmark)}</Box>
                            <Box w="100%">{expectedValue(expected)}</Box>
                          </HStack>
                          {index !== kpis.length - 1 && <Divider />}
                        </Fragment>
                      );
                    }
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <Center h="300px">
            <Text>Keine Daten vorhanden</Text>
          </Center>
        )}
      </Flex>
    </Box>
  );
};
