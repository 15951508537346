import { useState, useEffect } from 'react';
import { Finance } from 'components/Finance';
import _ from 'lodash';
import { useUser } from 'routes/UserProvider';

export const RevenuesPage = () => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading) {
    return null;
  }

  return <Finance type="revenues" />;
};
