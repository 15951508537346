import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  Divider,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { useCompanySettings } from './hooks/useCompanySettings';
import { FileFormField } from 'components/FileFormField';
import { AddGoal } from './components/AddGoal';
import { GoalConfirmationModal } from './components/GoalConfirmationModal';

export const CompanySettings = () => {
  const {
    initialData,
    modifiedData,
    setModifiedData,
    isLoading,
    currentYear,
    handleSubmit,
  } = useCompanySettings();

  // const [formData, setFormData] = useState({
  //   file: {
  //     preview:
  //       'https://whisperspublic.s3.eu-central-1.amazonaws.com/logos/default/logo.png',
  //   },
  // });

  const yearsArray = Array.from({ length: 2 }, (_, i) => currentYear + i);
  const yearsLabels = [
    'SETTINGS_COMPANY_REVENUE_GOALS_CURRENT_YEAR_LABEL',
    'SETTINGS_COMPANY_REVENUE_GOALS_NEXT_YEAR_LABEL',
  ];

  const initialGoals = initialData?.configuration?.revenueGoals.goals;
  const modifiedGoals = modifiedData?.configuration?.revenueGoals.goals;

  const setModifiedGoals = goals =>
    setModifiedData({
      ...modifiedData,
      configuration: {
        ...modifiedData.configuration,
        revenueGoals: {
          ...modifiedData.configuration.revenueGoals,
          goals,
        },
      },
    });

  const setNewGoalCallback = (newGoal, year, goalIndex) => {
    if (newGoal) {
      if (goalIndex === -1) {
        return setModifiedGoals([...modifiedGoals, newGoal]);
      } else {
        return setModifiedGoals(
          modifiedGoals.map(goal =>
            goal.year === newGoal.year ? newGoal : goal
          )
        );
      }
    } else {
      return setModifiedGoals(modifiedGoals.filter(goal => goal.year !== year));
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <VStack spacing={4} as="form" align="stretch" onSubmit={handleSubmit}>
          {/* <VStack spacing={4} align="stretch" w="100%">
            <Box>
              <Heading size="md">General</Heading>
              <Divider />
            </Box>
            <HStack spacing={10} alignItems="start">
              <Box w="30%">
                <FileFormField
                  type="logo"
                  file={formData.file}
                  setFile={file => setFormData({ ...formData, file: file })}
                />
              </Box>
              <Box w="70%">
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input type="text" />
                  <FormHelperText>
                    You can change your company name here.
                  </FormHelperText>
                </FormControl>
              </Box>
            </HStack>
          </VStack> */}
          <VStack spacing={4} align="stretch" w="100%">
            <Box>
              <Heading size="md">
                <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_TITLE" />
              </Heading>
              <Divider />
            </Box>

            <Text>
              <FormattedMessage id="SETTING_COMPANY_REVENUE_GOALS_DESCRIPTION" />
            </Text>

            {/* Create array with 2 elements */}
            {yearsArray.map((year, index) => {
              const label = yearsLabels[index];

              const goalIndex = initialGoals.findIndex(
                goal => goal.year === year
              );
              const goal = initialGoals[goalIndex]
                ? initialGoals[goalIndex]
                : { year, value: 0 };

              return (
                <AddGoal
                  key={year}
                  label={label}
                  goal={goal}
                  setNewGoalCallback={newGoal =>
                    setNewGoalCallback(newGoal, year, goalIndex)
                  }
                />
              );
            })}
          </VStack>
          <Box textAlign="right">
            {/* <Button
                size="lg"
                type="submit"
                colorScheme="whispersPrimary"
                borderRadius="15px"
              >
                Submit
              </Button> */}
            <GoalConfirmationModal onSubmit={handleSubmit} />
          </Box>
        </VStack>
      )}
    </Box>
  );
};
