export const isSeriesId = (params, seriesId) =>
  params.some(param => param.seriesId === seriesId);

const addRow = (col1, col2) => {
  return `<div style="display: flex; justify-content: space-between; gap: 10px;">
  <span>${col1}</span>
  <span>${col2}</span>
</div>`;
};

const generateTooltip = params => {
  let html = addRow('Date', `<b>${params[0].name}</b>`);

  if (isSeriesId(params, 'pre-existing') && isSeriesId(params, 'picked-up')) {
    const preExisting = params.find(param => param.seriesId === 'pre-existing');
    const pickedUp = params.find(param => param.seriesId === 'picked-up');
    html += addRow(
      '► Total on the books',
      `<b>${preExisting.value + pickedUp.value}</b>`
    );
    html += addRow(
      `${preExisting.marker} ${preExisting.seriesName}`,
      `<b>${preExisting.value}</b>`
    );
    html += addRow(
      `${pickedUp.marker} ${pickedUp.seriesName}`,
      `<b>${pickedUp.value}</b>`
    );
  } else if (isSeriesId(params, 'pre-existing')) {
    const preExisting = params.find(param => param.seriesId === 'pre-existing');
    html += addRow(
      `${preExisting.marker} ${preExisting.seriesName}`,
      `<b>${preExisting.value}</b>`
    );
  } else if (isSeriesId(params, 'picked-up')) {
    const pickedUp = params.find(param => param.seriesId === 'picked-up');
    html += addRow(
      `${pickedUp.marker} ${pickedUp.seriesName}`,
      `<b>${pickedUp.value}</b>`
    );
  }

  if (
    isSeriesId(params, 'pre-existing-ly') &&
    isSeriesId(params, 'picked-up-ly')
  ) {
    const preExistingLY = params.find(
      param => param.seriesId === 'pre-existing-ly'
    );
    const pickedUpLY = params.find(param => param.seriesId === 'picked-up-ly');
    html += addRow(
      '► Total on the books last year',
      `<b>${preExistingLY.value + pickedUpLY.value}</b>`
    );
    html += addRow(
      `${preExistingLY.marker} ${preExistingLY.seriesName}`,
      `<b>${preExistingLY.value}</b>`
    );
    html += addRow(
      `${pickedUpLY.marker} ${pickedUpLY.seriesName}`,
      `<b>${pickedUpLY.value}</b>`
    );
  } else if (isSeriesId(params, 'pre-existing-ly')) {
    const preExistingLY = params.find(
      param => param.seriesId === 'pre-existing-ly'
    );
    html += addRow(
      `${preExistingLY.marker} ${preExistingLY.seriesName}`,
      `<b>${preExistingLY.value}</b>`
    );
  } else if (isSeriesId(params, 'picked-up-ly')) {
    const pickedUpLY = params.find(param => param.seriesId === 'picked-up-ly');
    html += addRow(
      `${pickedUpLY.marker} ${pickedUpLY.seriesName}`,
      `<b>${pickedUpLY.value}</b>`
    );
  }

  return html;
};

export const createChartOption = ({
  dates,
  dataCurrentArrivals,
  dataCurrentPickups,
  dataLastYearArrivals,
  dataLastYearPickups,
}) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      label: {
        show: true,
      },
    },
    formatter: generateTooltip,
  },
  legend: {},
  grid: {
    top: '1%',
    left: '1%',
    right: '1%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      data: dates,
    },
  ],
  yAxis: [
    {
      type: 'value',
      name: 'Rooms',
    },
  ],
  dataZoom: [
    {
      show: true,
      start: 80,
      end: 100,
    },
  ],
  series: [
    {
      name: 'Pre-existing bookings',
      id: 'pre-existing',
      type: 'bar',
      stack: 'A',
      emphasis: {
        focus: 'series',
      },
      data: dataCurrentArrivals,
    },
    {
      name: 'Picked up rooms',
      id: 'picked-up',
      type: 'bar',
      stack: 'A',
      data: dataCurrentPickups,
      emphasis: {
        focus: 'series',
      },
    },
    {
      name: 'Pre-existing bookings last year',
      id: 'pre-existing-ly',
      type: 'bar',
      stack: 'B',
      emphasis: {
        focus: 'series',
      },
      data: dataLastYearArrivals,
    },
    {
      name: 'Picked up rooms last year',
      id: 'picked-up-ly',
      type: 'bar',
      stack: 'B',
      emphasis: {
        focus: 'series',
      },
      data: dataLastYearPickups,
    },
  ],
});
