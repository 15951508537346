import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import de from 'date-fns/locale/de';
import {
  Flex,
  Box,
  HStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomHeader } from './components/CustomHeader';
import { formatDateParam } from 'helpers/formatDate';

function formatDateToISO(date) {
  const d = new Date(date);

  const year = d.getFullYear(); // Obtiene el año como un número de cuatro dígitos (YYYY)
  const month = d.getMonth() + 1; // getMonth() devuelve un índice basado en cero, por lo que +1
  const day = d.getDate(); // Obtiene el día del mes como un número

  // Asegura que el mes y el día sean siempre dos dígitos
  const formattedMonth = month < 10 ? '0' + month : month;
  const formattedDay = day < 10 ? '0' + day : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

export const DateRangeFilter = ({ dates, setDates }) => {
  registerLocale('de', de);

  const [startDate, setStartDate] = useState(
    dates.startDate ? new Date(dates.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    dates.endDate ? new Date(dates.endDate) : null
  );
  const datesRef = useRef();

  const onClickFilter = onClose => {
    setDates({
      startDate: formatDateToISO(startDate),
      endDate: formatDateToISO(endDate),
    });
    onClose();
  };

  return (
    <Box>
      <Popover closeOnBlur placement="bottom" initialFocusRef={datesRef}>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant="ghost"
                borderColor="#B3BABE"
                color="#495B66"
                borderRadius="15px"
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              >
                {endDate ? (
                  <Text>
                    {formatDateParam(startDate)} - {formatDateParam(endDate)}
                  </Text>
                ) : (
                  <Text>
                    <FormattedMessage id="FILTERS_DATES_NAME" />
                  </Text>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent w="100%">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                <FormattedMessage id="FILTERS_DATES_HEADER" />
              </PopoverHeader>
              <PopoverBody>
                <HStack alignItems="flex-start">
                  <Box>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      bg="#f0f0f0"
                      mb="5px"
                    >
                      <FormattedMessage id="FILTERS_DATES_START_DATE" />
                    </Text>
                    <DatePicker
                      locale="de"
                      selected={startDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      onChange={date => {
                        setStartDate(date);
                      }}
                      renderCustomHeader={CustomHeader}
                      inline
                    />
                  </Box>
                  <Box>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      bg="#f0f0f0"
                      mb="5px"
                    >
                      <FormattedMessage id="FILTERS_DATES_END_DATE" />
                    </Text>
                    <DatePicker
                      locale="de"
                      selected={endDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      onChange={date => startDate && setEndDate(date)}
                      renderCustomHeader={CustomHeader}
                      disabled={!startDate}
                      inline
                    />
                  </Box>
                </HStack>
                {!startDate && (
                  <Flex justifyContent="center">
                    <Text fontSize="sm">
                      <FormattedMessage id="FILTERS_DATES_FOOTER_MESSAGE" />
                    </Text>
                  </Flex>
                )}
              </PopoverBody>
              <PopoverFooter>
                <Flex justifyContent="flex-end">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    borderRadius="15px"
                    onClick={() => onClickFilter(onClose)}
                    isDisabled={!endDate}
                  >
                    <FormattedMessage id="FILTERS_FILTER_BUTTON" />
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
