import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Flex,
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  VStack,
  CheckboxGroup,
  Checkbox,
  Text,
  Tag,
  HStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import _ from 'lodash';

export const TypesFilter = ({ types, setTypes }) => {
  const typesRef = useRef();

  const onClickRemoveFilter = onClose => {
    setTypes([]);
    onClose();
  };

  return (
    <Box>
      <Popover closeOnBlur placement="bottom" initialFocusRef={typesRef}>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                borderColor="#B3BABE"
                color="#495B66"
                variant="outline"
                borderRadius="15px"
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              >
                <HStack>
                  <Text>
                    <FormattedMessage id="FILTERS_TYPES_NAME" />
                  </Text>
                  {!_.isEmpty(types) && (
                    <Tag
                      size="xs"
                      bg="#f6bc07"
                      p="2px 7px"
                      borderRadius="full"
                      justifyContent="center"
                    >
                      <Text color="black" fontSize="14px">
                        {types.length}
                      </Text>
                    </Tag>
                  )}
                </HStack>
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                <FormattedMessage id="FILTERS_TYPES_HEADER" />
              </PopoverHeader>
              <PopoverBody>
                <CheckboxGroup
                  alignItems="flex-start"
                  value={types}
                  onChange={setTypes}
                >
                  <VStack spacing={2} align="stretch">
                    <Checkbox value="fortschritt">Fortschritt</Checkbox>
                    <Checkbox value="bericht">Bericht</Checkbox>
                    <Checkbox value="vorschau">Vorschau</Checkbox>
                    <Checkbox value="analyse">Analyse</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </PopoverBody>
              <PopoverFooter>
                <Flex justifyContent="space-between">
                  <Button
                    size="sm"
                    variant="ghost"
                    leftIcon={<CloseIcon />}
                    color="#f6bc07"
                    borderRadius="15px"
                    mr={2}
                    onClick={() => onClickRemoveFilter(onClose)}
                    isDisabled={_.isEmpty(types)}
                  >
                    <FormattedMessage id="FILTERS_REMOVE_FILTER_BUTTON" />
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
