import { Flex, HStack } from '@chakra-ui/react';
import { DateFilter } from './components/DateFilter';

export const Filters = ({ data, enabledDates, isLoading, setData }) => {
  const setDate = date => setData(prevData => ({ ...prevData, date }));

  return (
    <Flex mb="20px" justifyContent="right">
      <HStack spacing="5px">
        <DateFilter
          date={data?.date}
          enabledDates={enabledDates}
          isLoading={isLoading}
          setDate={setDate}
        />
      </HStack>
    </Flex>
  );
};
