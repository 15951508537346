import { createContext, useContext } from 'react';
import { useUser } from 'routes/UserProvider';
import { useFetchWidgets } from './hooks/useFetchWidgets';
import { useUserWidgets } from './hooks/useUserWidgets';

const WidgetsContext = createContext();

const useWidgets = () => {
  const context = useContext(WidgetsContext);

  if (!context) {
    throw new Error('useWidgets must be used within a WidgetsProvider');
  }

  const { widgets, userWidgets, updateWidgets } = context;

  return { widgets, userWidgets, updateWidgets };
};

const WidgetsProvider = ({ children }) => {
  const { user, updateUserProfileWidgets } = useUser();
  const { widgets } = useFetchWidgets();
  const [userWidgets, updateWidgets] = useUserWidgets(
    user,
    updateUserProfileWidgets,
    widgets
  );

  const value = {
    widgets,
    userWidgets,
    updateWidgets,
  };

  return (
    <WidgetsContext.Provider value={value}>{children}</WidgetsContext.Provider>
  );
};

export { useWidgets, WidgetsProvider };
