import { useState, useEffect } from 'react';

export const useUserWidgets = (user, updateUserProfileWidgets, widgets) => {
  const [userWidgets, setUserWidgets] = useState([]);

  const updateData = newWidgets => {
    if (newWidgets.length > 0) {
      const widgetsToSave = newWidgets.map(widget => ({
        id: widget.id,
        _id: widget._id,
        title: widget.title,
        collapse: widget.collapse || false,
      }));
      updateUserProfileWidgets(widgetsToSave);
    } else {
      updateUserProfileWidgets([]);
    }
  };

  useEffect(() => {
    if (widgets.length > 0 && user?.profile?.widgets?.length > 0) {
      const userWidgets = user.profile.widgets.reduce((acc, userWidget) => {
        const widgetFound = widgets.find(widget => widget.id === userWidget.id);
        if (widgetFound) {
          acc.push({
            ...widgetFound,
            _id: userWidget._id,
            collapse: userWidget.collapse,
          });
        }

        return acc;
      }, []);

      setUserWidgets(userWidgets);
    } else {
      setUserWidgets([]);
    }
  }, [user?.profile?.widgets, widgets]);

  return [userWidgets, updateData];
};
