import { useRef, useState } from 'react';
import { Flex, HStack } from '@chakra-ui/react';
import _ from 'lodash';
import { TypesFilter } from './components/TypesFilter';
import { DatesFilter } from './components/DatesFilter';

export const Filters = ({ query, setQuery }) => {
  const setTypes = types => {
    setQuery({ ...query, types });
  };

  const setDates = dates => {
    if (!_.isEmpty(dates)) {
      setQuery({ ...query, ...dates });
    } else {
      setQuery(_.omit(query, ['startDate', 'endDate']));
    }
  };

  return (
    <Flex mb="20px" justifyContent="right">
      <HStack spacing="5px">
        <TypesFilter types={query?.types} setTypes={setTypes} />
        <DatesFilter dates={query?.dates} setDates={setDates} />
      </HStack>
    </Flex>
  );
};
