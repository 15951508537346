export const COLORS = {
  fortschritt: {
    headerBackground: '#F6BC06',
    bodyBackground: '#F8EBC5',
    headerHeadline: '#A47A2A',
    categoryTitle: '#F9E4A5',
    bodyHeadline: '#A47A2A',
  },
  bericht: {
    headerBackground: '#6F8CA7',
    bodyBackground: '#DBE4ED',
    headerHeadline: '#404F5C;',
    categoryTitle: '#BBCBD9;',
    bodyHeadline: '#37648E',
  },
  vorschau: {
    headerBackground: '#9B5069',
    bodyBackground: '#F1E8EB',
    headerHeadline: '#490A1F',
    categoryTitle: '#FAACC6',
    bodyHeadline: '#9B5069',
  },
  analyse: {
    headerBackground:
      'linear-gradient(91.33deg, rgba(169, 123, 24, 0.778646) 4.58%, rgba(250, 186, 61, 0.5) 22.51%, #A0853E 69.05%, #CDA53E 95.69%)',
    bodyBackground: '#EAE0CE',
    headerHeadline: '#705731',
    categoryTitle: '#E2D8BF',
    bodyHeadline: '#9D8237',
  },
};

export const DEFAULT_COLORS = {
  headerBackground: '#000',
  bodyBackground: '#F8EBC5',
  headerHeadline: '#F9E4A5',
  categoryTitle: '#A47A2A',
  bodyHeadline: '#A47A2A',
};

export const BUTTONS = {
  fortschritt: {
    normal: '#E8B107',
    hover: '#FAC92F',
    pressed: '#CA9B08',
  },
  bericht: {
    normal: '#37648E',
    hover: '#6F8CA7',
    pressed: '#404F5C',
  },
  vorschau: {
    normal: '#C8839A',
    hover: '#DBA6B8',
    pressed: '#AA6C81',
  },
  analyse: {
    normal: '#BCA25C',
    hover: '#DAC07B',
    pressed: '#A68C46',
  },
};

export const ADDITIONAL_COLORS = {
  positiveFigure: '#3D9826',
  negativeFigure: '#B17253',
  continuousText: '#4F4E53',
};
