import { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useUser } from 'routes/UserProvider';
import { useInfiniteScroll } from './useInfiniteScroll';
import { fetchNotifications } from '../services/api';

export const useNews = () => {
  const { user } = useUser();
  const hasMoreDataRef = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({});
  const [data, setData] = useState([]);

  const loadMore = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: newData } = await fetchNotifications({
        lastNotificationId: data[data.length - 1].id,
        ...query,
      });

      if (!_.isEmpty(newData)) {
        const { hasMore, notifications } = newData;
        setData(prev => [...prev, ...notifications]);
        hasMoreDataRef.current = hasMore;
      } else {
        hasMoreDataRef.current = false;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [data, query]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchNotifications(query);
        if (!_.isEmpty(data)) {
          const { hasMore, notifications } = data;
          setData(notifications);
          hasMoreDataRef.current = hasMore;
        } else {
          hasMoreDataRef.current = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.company) {
      fetchData();
    }
  }, [user, query]);

  const infiniteScrollRef = useInfiniteScroll(loadMore, hasMoreDataRef);

  return { infiniteScrollRef, isLoading, query, setQuery, data };
};
