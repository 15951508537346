import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import de from 'date-fns/locale/de';
import {
  Flex,
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomHeader } from './components/CustomHeader';
import { ExpendituresCustomHeader } from './components/ExpendituresCustomHeader';
import { formatDateParam, formatDateMonthYearParam } from 'helpers/formatDate';

export const DateFilter = ({
  date,
  enabledDates,
  isExpendituresView,
  isLoading,
  setDate,
}) => {
  const dateRef = useRef();
  registerLocale('de', de);

  const onClickRemoveFilter = onClose => {
    setDate(null);
    onClose();
  };

  const onDateChange = (date, onClose) => {
    const formattedDate = isExpendituresView
      ? formatDateMonthYearParam(date)
      : formatDateParam(date);
    setDate(formattedDate);

    onClose();
  };

  const showDateTitle = date => {
    if (!date) {
      return 'Keine Daten';
    }

    const options = {};
    if (isExpendituresView) {
      options.year = 'numeric';
      options.month = 'long';
    } else {
      options.year = 'numeric';
      options.month = 'long';
      options.day = 'numeric';
    }

    return new Date(parseDate(date)).toLocaleDateString('de-DE', options);
  };

  // stringDate can be in format yyyy/mm or yyyy/mm/dd
  const parseDate = stringDate => {
    const dateParts = stringDate.split('/');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2] ? dateParts[2] : 1);

    return new Date(year, month - 1, day);
  };

  // const renderMonthContent = (month, shortMonth, longMonth) => {
  //   const tooltipText = `Tooltip for month: ${longMonth}`;
  //   return <span title={tooltipText}>{longMonth}</span>;
  // };

  return (
    <Box>
      <Popover closeOnBlur placement="bottom" initialFocusRef={dateRef}>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant="ghost"
                borderColor="#B3BABE"
                color="#495B66"
                borderRadius="15px"
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                isDisabled={isLoading || !date}
              >
                <Text>
                  {isLoading ? 'Loading date...' : showDateTitle(date)}
                </Text>
              </Button>
            </PopoverTrigger>
            <PopoverContent w="100%">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                {/* <FormattedMessage id="Select the date to filter" /> */}
                Wählen Sie das Datum
              </PopoverHeader>
              <PopoverBody>
                {date && (
                  <DatePicker
                    locale="de"
                    selected={new Date(parseDate(date))}
                    // selectsStart
                    onChange={date => onDateChange(date, onClose)}
                    renderCustomHeader={
                      isExpendituresView
                        ? ExpendituresCustomHeader
                        : CustomHeader
                    }
                    showMonthYearPicker={isExpendituresView}
                    includeDates={enabledDates.map(
                      date => new Date(parseDate(date))
                    )}
                    inline
                  />
                )}
              </PopoverBody>
              <PopoverFooter>
                <Flex justifyContent="space-between">
                  <Button
                    size="sm"
                    variant="ghost"
                    leftIcon={<CloseIcon />}
                    color="#f6bc07"
                    borderRadius="15px"
                    mr={2}
                    onClick={() => onClickRemoveFilter(onClose)}
                    isDisabled={!date}
                  >
                    <FormattedMessage id="FILTERS_REMOVE_FILTER_BUTTON" />
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
