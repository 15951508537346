import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  HStack,
  Text,
  VStack,
  Button,
  InputGroup,
  Input,
  InputRightAddon,
  Alert,
  AlertIcon,
  IconButton,
  AlertDescription,
} from '@chakra-ui/react';
import { CheckIcon, EditIcon, DeleteIcon, CloseIcon } from '@chakra-ui/icons';

export const AddGoal = ({ label, goal = {}, setNewGoalCallback }) => {
  const { year: goalYear, value: goalValue } = goal;
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [newGoal, setNewGoal] = useState(null);

  const handleAddGoal = () => setShowInput(true);

  const handleOnChange = event => setInputValue(event.target.value);

  const handleOnSave = () => {
    const goalToAdd = {
      year: goalYear,
      value: parseFloat(inputValue),
    };
    setNewGoal(goalToAdd);
    setNewGoalCallback(goalToAdd);
    setShowInput(false);
  };

  const handleOnCancel = () => {
    setShowInput(false);
    setInputValue(newGoal?.value || 0);
  };

  const handleOnRemove = () => {
    setShowInput(false);
    setInputValue(0);
    setNewGoal(null);
    setNewGoalCallback(goalValue ? goal : null);
  };

  const formatValueToCurrency = value =>
    value.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  useEffect(() => {
    setNewGoal(null);
    setInputValue(0);
  }, [goalValue]);

  const renderGoalValue = value => (
    <Text fontWeight="light" fontSize={25}>
      {Number(value) ? (
        formatValueToCurrency(Number(value))
      ) : (
        <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_UNDEFINED_GOAL_TEXT" />
      )}
    </Text>
  );

  const renderInput = () => (
    <InputGroup size="md" w="200px">
      <Input
        type="number"
        placeholder='e.g. "100000,00"'
        size="md"
        value={inputValue || ''}
        onChange={handleOnChange}
      />
      <InputRightAddon children="€" />
    </InputGroup>
  );

  return (
    <VStack align="stretch">
      <Text fontWeight="bold">
        <FormattedMessage id={label} />
      </Text>
      <HStack spacing={6}>
        {renderGoalValue(goalValue)}
        {showInput ? (
          <>
            <Text>►</Text>
            {renderInput()}
            <IconButton
              size="md"
              colorScheme="whispersSecondary"
              aria-label="Save"
              borderRadius={15}
              icon={<CheckIcon />}
              onClick={handleOnSave}
              isDisabled={!inputValue}
            />
            <IconButton
              size="md"
              colorScheme="red"
              variant="outline"
              aria-label="Close"
              borderRadius={15}
              icon={<CloseIcon />}
              onClick={handleOnCancel}
            />
          </>
        ) : newGoal ? (
          <>
            <Text>►</Text>
            {renderGoalValue(newGoal.value)}
            <IconButton
              size="md"
              colorScheme="whispersSecondary"
              aria-label="Edit"
              borderRadius={15}
              icon={<EditIcon />}
              onClick={handleAddGoal}
            />
            <IconButton
              size="md"
              colorScheme="red"
              variant="outline"
              aria-label="Delete"
              borderRadius={15}
              icon={<DeleteIcon />}
              onClick={handleOnRemove}
            />
          </>
        ) : (
          <Button
            size="md"
            colorScheme="whispersSecondary"
            variant="outline"
            borderRadius={15}
            onClick={handleAddGoal}
          >
            <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_ADD_GOAL_BUTTON" />
          </Button>
        )}
      </HStack>
      {!showInput && newGoal && (
        <Alert status="warning">
          <AlertIcon />
          <AlertDescription>
            <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_GOAL_WARNING" />
          </AlertDescription>
        </Alert>
      )}
    </VStack>
  );
};
