import { useState, useEffect } from 'react';
import { useUser } from 'routes/UserProvider';
import { fetchCashier } from '../services/api';
import { padTo2Digits } from 'helpers/padTo2Digits';

const generateInitialDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate() - 1; // - 1 because we want to show the previous day

  return `${year}/${padTo2Digits(month)}/${padTo2Digits(day)}`;
};

export const useCashierPage = () => {
  const { user } = useUser();
  const [query, setQuery] = useState({
    period: 'TAG',
    date: generateInitialDate(),
    sorting: { field: 'totalRevenue.value', order: 'DESC' },
  });
  const [data, setData] = useState(null);
  const [enabledDates, setEnabledDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const filtersConfig = [
    {
      type: 'Period-Filter-V1',
      options: [
        { label: 'TAG', value: 'TAG' },
        { label: 'WOCHE', value: 'WOCHE' },
        { label: 'MONAT', value: 'MONAT' },
      ],
      setPeriod: period => setQuery(prevData => ({ ...prevData, period })),
      period: query.period,
    },
    {
      type: 'Date-Filter-V1',
      setDate: date => setQuery(prevData => ({ ...prevData, date })),
      date: query.date,
      enabledDates,
      isLoading,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchCashier(query);
        if (Object.keys(data).length > 0) {
          setData(data?.cashier);
          setEnabledDates(data?.enabledDates);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.company) {
      fetchData();
    }
  }, [user, query]);

  return { data, isLoading, setQuery, enabledDates, query, filtersConfig };
};
