import { useState } from 'react';
import jwtDecode from 'jwt-decode';

export const useLocalStorageToken = () => {
  const [decodedToken, setDecodedToken] = useState(() => {
    try {
      const token = window.localStorage.getItem('token');
      if (token) {
        const tokenDecoded = jwtDecode(token);
        if (tokenDecoded.exp > Date.now() / 1000) {
          return tokenDecoded;
        } else {
          window.localStorage.removeItem('token');
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  });

  const setToken = token => {
    try {
      if (token) {
        window.localStorage.setItem('token', token);
        setDecodedToken(jwtDecode(token));
      } else {
        window.localStorage.removeItem('token');
        setDecodedToken(token);
      }
    } catch (error) {
      console.error('Unable to save value to local storage');
    }
  };

  return [decodedToken, setToken];
};
