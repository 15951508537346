import { Grid, GridItem } from '@chakra-ui/react';
import { News } from 'components/News';
import { Widgets } from 'components/Widgets';

export const NewsPage = () => (
  <Grid
    templateAreas={`
    "main widgets"
    "main widgets"
  `}
    gridTemplateColumns={'2fr 1fr'}
    gridTemplateRows={'auto'}
    gap={{
      sm: '0px 10px',
      md: '0px 20px',
      lg: '0px 30px',
      xl: '0px 40px',
      '2xl': '0px 50px',
    }}
  >
    <GridItem area={'main'}>
      <News />
    </GridItem>
    <GridItem
      pr="20px"
      area={'widgets'}
      position="sticky"
      top="0"
      h="calc(100vh - 110px)"
      boxSizing="border-box"
    >
      <Widgets />
    </GridItem>
  </Grid>
);
