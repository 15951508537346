import { Box } from '@chakra-ui/react';
import { WidgetsProvider } from './WidgetsProvider';
import { AddWidgetModal } from './components/AddWidgetModal';
import { Draggable } from './components/Draggable';

export const Widgets = () => (
  <WidgetsProvider>
    <Box width="100%" mt="20px" textAlign="right" overflowY="auto">
      <AddWidgetModal />
      <Draggable />
    </Box>
  </WidgetsProvider>
);
