import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalFooter,
  ButtonGroup,
  Button,
  Input,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

export const GoalConfirmationModal = ({ onSubmit }) => {
  const { formatMessage } = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState('');
  const isDisabled = value !== 'OK';

  const handleChange = event => {
    const { value } = event.target;

    setValue(value);
  };

  const handleOnClose = () => {
    setValue('');
    onClose();
  };

  const handleOnSubmit = event => {
    onSubmit(event);
    handleOnClose();
  };

  return (
    <>
      <Button
        size="lg"
        colorScheme="whispersPrimary"
        borderRadius="15px"
        onClick={onOpen}
      >
        <FormattedMessage id="SETTINGS_SUBMIT_BUTTON" />
      </Button>
      <Modal
        size="lg"
        closeOnOverlayClick={false}
        onClose={handleOnClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_CONFIRMATION_MODAL_TITLE" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6} textAlign="justify" align="stretch">
              <Text>
                <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_CONFIRMATION_MODAL_DESCRIPTION_1" />
              </Text>
              <Text>
                <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_CONFIRMATION_MODAL_DESCRIPTION_2" />
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={6}>
              <Input
                size="lg"
                value={value}
                placeholder={formatMessage({
                  id: 'SETTINGS_COMPANY_REVENUE_GOALS_CONFIRMATION_MODAL_INPUT_PLACEHOLDER',
                })}
                onChange={handleChange}
              />
              <ButtonGroup spacing="2">
                <Button
                  size="lg"
                  colorScheme="whispersPrimary"
                  borderRadius="15px"
                  onClick={handleOnSubmit}
                  isDisabled={isDisabled}
                >
                  <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_CONFIRMATION_MODAL_CONFIRM_BUTTON" />
                </Button>
                <Button
                  size="lg"
                  colorScheme="whispersTertiary"
                  borderRadius="15px"
                  variant="outline"
                  onClick={handleOnClose}
                >
                  <FormattedMessage id="SETTINGS_COMPANY_REVENUE_GOALS_CONFIRMATION_MODAL_CANCEL_BUTTON" />
                </Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
