import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useUser } from 'routes/UserProvider';
import { fetchFinance } from '../services/api';
import { padTo2Digits } from 'helpers/padTo2Digits';

const generateInitialDate = type => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate() - 1; // - 1 because we want to show the previous day

  if (type === 'expenditures') {
    return `${year}/${padTo2Digits(month)}`;
  }

  return `${year}/${padTo2Digits(month)}/${padTo2Digits(day)}`;
};

export const useFinance = ({ type }) => {
  const { user } = useUser();
  const [query, setQuery] = useState({
    type,
    period: type === 'expenditures' ? 'MONAT' : 'TAG',
    date: generateInitialDate(type),
  });
  const [data, setData] = useState(null);
  const [enabledDates, setEnabledDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchFinance(query);
        if (!_.isEmpty(data)) {
          setData(data?.finance);
          setEnabledDates(data?.enabledDates);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.company) {
      fetchData();
    }
  }, [user, query]);

  return { setQuery, isLoading, data, enabledDates, query };
};
