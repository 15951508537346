import { FormattedMessage } from 'react-intl';
import { VStack, Flex, Text, Icon, Tooltip } from '@chakra-ui/react';
import { FaCity, FaHotel } from 'react-icons/fa';

export const benchmarkValue = ({ all, one, color }) => {
  const colors = {
    '-1': 'red.400',
    0: 'black',
    1: 'green.400',
  };

  return (
    <VStack>
      <Tooltip
        label={
          <FormattedMessage id="benchmarking.table.td.benchmark.all.tooltip" />
        }
        placement="right"
      >
        <Flex alignItems="center">
          <Icon as={FaCity} boxSize={4} mr="2" />
          <Text>{all}</Text>
        </Flex>
      </Tooltip>
      <Tooltip
        label={
          <FormattedMessage id="benchmarking.table.td.benchmark.one.tooltip" />
        }
        placement="right"
      >
        <Flex alignItems="center">
          <Icon as={FaHotel} boxSize={4} mr="2" />
          <Text color={colors[color]} fontWeight="bold">
            {one}
          </Text>
        </Flex>
      </Tooltip>
    </VStack>
  );
};
