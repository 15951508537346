import { IconButton } from '@chakra-ui/react';
import {
  // FaSortAlphaDown,
  // FaSortAlphaUp,
  // FaSortAmountUp,
  // FaSortAmountDown,
  // FaSortNumericDown,
  // FaSortNumericUp,
  FaSort,
} from 'react-icons/fa';

export const SortButton = ({
  colorScheme,
  setSorting,
  sorting,
  sortValue,
  type,
}) => {
  const defaultOrder = type === 'number' ? 'DESC' : 'ASC';
  const isSorted = sorting?.field === sortValue || false;
  const isDesc = sorting?.order === 'DESC' || false;

  const handleClick = () =>
    setSorting(prevState => ({
      ...prevState,
      sorting: {
        field: sortValue,
        order: isSorted ? (isDesc ? 'ASC' : 'DESC') : defaultOrder,
      },
    }));

  return (
    <IconButton
      ml={1}
      size="xs"
      variant="ghost"
      color="white"
      colorScheme={colorScheme}
      aria-label="Sort button"
      onClick={handleClick}
      icon={<FaSort />}
      isActive={isSorted}
    />
  );
};
