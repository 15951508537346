import { useCallback, useRef } from 'react';

export const useInfiniteScroll = (loadMoreCallback, hasMoreDataRef) => {
  const observer = useRef(null);

  const callback = useCallback(
    entries => {
      if (entries[0].isIntersecting) {
        if (hasMoreDataRef.current) {
          loadMoreCallback();
        }
      }
    },
    [loadMoreCallback, hasMoreDataRef]
  );

  const infiniteScrollRef = useCallback(
    node => {
      if (observer.current) {
        observer.current.disconnect();
      }

      if (node) {
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(node);
      }
    },
    [callback]
  );

  return infiniteScrollRef;
};
