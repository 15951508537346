import {
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
} from '@chakra-ui/react';
import { BiShow, BiHide } from 'react-icons/bi';

export const PasswordInput = ({
  isVisible,
  name,
  onChangeInput,
  placeholder,
  setIsVisible,
  value,
}) => {
  return (
    <InputGroup size="lg">
      <Input
        type={isVisible ? 'text' : 'password'}
        name={name}
        autoComplete="new-password"
        placeholder={placeholder}
        value={value}
        onChange={onChangeInput}
        borderRadius="15px"
        bg="#fff"
      />
      <InputRightElement>
        <Button
          size="lg"
          variant="outline"
          borderStartRadius="0px"
          borderEndRadius="15px"
          onClick={setIsVisible}
          border="none"
        >
          <Icon as={isVisible ? BiShow : BiHide} color="whispersPrimary.600" />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
