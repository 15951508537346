import { useState, useEffect } from 'react';
import { useAuth } from 'routes/AuthProvider';
import { fetchUserById, updateUser } from '../services/api';

export const useUserHook = () => {
  const { auth } = useAuth();
  const [user, setUser] = useState({});

  const updateUserData = async userData => {
    try {
      const { data: updatedUser } = await updateUser(auth.userId, userData);
      setUser({ ...updatedUser, company: user.company });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchUserById(auth.userId);
        if (data) {
          setUser(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [auth]);

  return [user, updateUserData];
};
