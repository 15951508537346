import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import de from 'date-fns/locale/de';
import {
  Flex,
  Box,
  HStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomHeader } from './components/CustomHeader';
import { formatDateParam } from 'helpers/formatDate';

export const DatesFilter = ({ dates, setDates }) => {
  const [startDate, setStartDate] = useState(
    dates?.startDate ? new Date(dates.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    dates?.endDate ? new Date(dates.endDate) : null
  );
  const datesRef = useRef();
  registerLocale('de', de);

  const onClickRemoveFilter = onClose => {
    setStartDate(null);
    setEndDate(null);
    setDates({});
    onClose();
  };

  const onClickFilter = onClose => {
    setDates({
      startDate: formatDateParam(startDate),
      endDate: formatDateParam(endDate),
    });
    onClose();
  };
  return (
    <Box>
      <Popover closeOnBlur placement="bottom" initialFocusRef={datesRef}>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant="ghost"
                borderColor="#B3BABE"
                color="#495B66"
                borderRadius="15px"
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              >
                {endDate ? (
                  <Text>
                    {formatDateParam(startDate)} - {formatDateParam(endDate)}
                  </Text>
                ) : (
                  <Text>
                    <FormattedMessage id="FILTERS_DATES_NAME" />
                  </Text>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent w="100%">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                <FormattedMessage id="FILTERS_DATES_HEADER" />
              </PopoverHeader>
              <PopoverBody>
                <HStack alignItems="flex-start">
                  <Box>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      bg="#f0f0f0"
                      mb="5px"
                    >
                      <FormattedMessage id="FILTERS_DATES_START_DATE" />
                    </Text>
                    <DatePicker
                      locale="de"
                      selected={startDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      onChange={date => setStartDate(date)}
                      renderCustomHeader={CustomHeader}
                      inline
                    />
                  </Box>
                  <Box>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      bg="#f0f0f0"
                      mb="5px"
                    >
                      <FormattedMessage id="FILTERS_DATES_END_DATE" />
                    </Text>
                    <DatePicker
                      locale="de"
                      selected={endDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      onChange={date => startDate && setEndDate(date)}
                      renderCustomHeader={CustomHeader}
                      disabled={!startDate}
                      inline
                    />
                  </Box>
                </HStack>
                {!startDate && (
                  <Flex justifyContent="center">
                    <Text fontSize="sm">
                      <FormattedMessage id="FILTERS_DATES_FOOTER_MESSAGE" />
                    </Text>
                  </Flex>
                )}
              </PopoverBody>
              <PopoverFooter>
                <Flex justifyContent="space-between">
                  <Button
                    size="sm"
                    variant="ghost"
                    leftIcon={<CloseIcon />}
                    color="#f6bc07"
                    borderRadius="15px"
                    mr={2}
                    onClick={() => onClickRemoveFilter(onClose)}
                    isDisabled={!endDate}
                  >
                    <FormattedMessage id="FILTERS_REMOVE_FILTER_BUTTON" />
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    borderRadius="15px"
                    onClick={() => onClickFilter(onClose)}
                    isDisabled={!endDate}
                  >
                    <FormattedMessage id="FILTERS_FILTER_BUTTON" />
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
