import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  SimpleGrid,
  Box,
  Checkbox,
  Button,
} from '@chakra-ui/react';
import { BiShow, BiHide } from 'react-icons/bi';
import { useAuth } from 'routes/AuthProvider';
import { fetchUserToken } from './services/api';

export const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const { login } = useAuth();
  const navigate = useNavigate();

  const onChangeInput = e => {
    const { name, value } = e.target;
    setValues(prev => ({ ...prev, [name]: value }));
    setIsError(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await fetchUserToken(values);
      if (data?.token && typeof data?.token === 'string') {
        if (data?.isOTT) {
          return navigate(`/change-password/${data?.token}`);
        }

        return login(data?.token);
      } else {
        throw new Error('The token is not valid or is missing.');
      }
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack
      bg="rgba(250, 250, 250, 0.9)"
      borderRadius="15px"
      flexDir="column"
      mb="2"
      textAlign="center"
      padding="30px 40px"
      shadow="2xl"
      maxWidth="500px"
    >
      <Heading
        as="h1"
        fontSize={['lg', 'xl', '2xl', '3xl', '4xl']}
        fontWeight="500"
        fontStyle="italic"
      >
        Willkommen!
      </Heading>
      <Heading
        as="h2"
        fontSize={{ base: 'xs', lg: 'sm' }}
        letterSpacing={3}
        fontWeight="900"
        // color="#37648E"
        color="whispersPrimary.600"
      >
        ANMELDEN
      </Heading>
      {isError && (
        <Alert status="error" variant="left-accent">
          <AlertIcon />
          <Box textAlign="left">
            <AlertTitle>Login failed!</AlertTitle>
            <AlertDescription>Incorrect email or password.</AlertDescription>
          </Box>
        </Alert>
      )}
      <form>
        <Stack pt="20px" spacing={4}>
          <FormControl isInvalid={isError}>
            <FormLabel>Email-Adresse</FormLabel>
            <Input
              size="lg"
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Deine Email Addresse"
              value={values.email}
              onChange={onChangeInput}
              borderRadius="15px"
              bg="#fff"
            />
            {isError && <FormErrorMessage>Email is required.</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={isError}>
            <FormLabel>Passwort</FormLabel>
            <InputGroup size="lg">
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="current-password"
                placeholder="Dein Passwort"
                value={values.password}
                onChange={onChangeInput}
                borderRadius="15px"
                bg="#fff"
              />
              <InputRightElement>
                <Button
                  size="lg"
                  variant="outline"
                  borderStartRadius="0px"
                  borderEndRadius="15px"
                  onClick={() => setShowPassword(!showPassword)}
                  border="none"
                >
                  <Icon
                    as={showPassword ? BiHide : BiShow}
                    color="whispersPrimary.600"
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
            {isError && (
              <FormErrorMessage>Password is required.</FormErrorMessage>
            )}
          </FormControl>
          <SimpleGrid columns={2} spacing={10}>
            <Box textAlign={{ sm: 'center', md: 'left' }}>
              <Checkbox defaultChecked>Angemeldet bleiben</Checkbox>
            </Box>
            {/* <Box textAlign={{ sm: 'center', md: 'right' }}>
              <Button
                as={Link}
                // to="/recover-password"
                colorScheme="whispersPrimary"
                variant="link"
              >
                Passwort vergessen?
              </Button>
            </Box> */}
          </SimpleGrid>
          <Box textAlign="center">
            <Button
              size="lg"
              type="submit"
              colorScheme="whispersPrimary"
              borderRadius="15px"
              onClick={handleSubmit}
              isLoading={isLoading}
              loadingText="Anmelden"
              isDisabled={!values.email || !values.password || isError}
            >
              Anmelden
            </Button>
          </Box>
        </Stack>
      </form>
      {/* <Text textAlign="center" pt="20px">
        Du hast kein Konto?{' '}
        <Link to="/register">
          <Button colorScheme="whispersPrimary" variant="link">
            Registrieren
          </Button>
        </Link>
      </Text> */}
    </Stack>
  );
};
