import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { device } from 'helpers/responsive';
import { dateBox } from 'helpers/formatDate';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 60px;
  background: ${props => props.headerBackground};
  padding-left: 5px;
  padding-top: 2px;
  width: 100%;
  height: 128px;
  @media ${device.laptopL} {
    width: 631px;
    height: 92px;
    border-top-right-radius: 57px;
  }
`;

const ColLeft = styled.div`
  text-align: left;
  color: white;
  font-weight: 700;
  font-size: 14px;
  @media ${device.laptopL} {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
  }
`;

const ColRight = styled.div`
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  text-align: end;
`;

const Title = styled.h1`
  margin: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 53px;
  padding-top: 10px;
  color: ${props => props.headerHeadline};
  @media ${device.laptopL} {
    font-weight: 500;
    font-size: 36px;
    line-height: 25px;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

const Type = styled.h2`
  text-align: -webkit-match-parent;
  font-weight: 500;
  font-size: 35px;
  font-style: italic;
  color: ${props => props.categoryTitle};
  text-transform: lowercase;
  @media ${device.laptopL} {
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    padding-top: 10px;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

// const padTo2Digits = num => num.toString().padStart(2, '0');

// const formatDate = date =>
//   [
//     padTo2Digits(date.getDate()),
//     padTo2Digits(date.getMonth() + 1),
//     date.getFullYear(),
//   ].join('/');

const getDate = date => {
  const dateToDisplay = new Date(date);
  // const currentDate = new Date();

  // if (formatDate(dateToDisplay) === formatDate(currentDate)) {
  //   return `Heute ${padTo2Digits(dateToDisplay.getHours())}:${padTo2Digits(
  //     dateToDisplay.getSeconds()
  //   )}`;
  // }

  return dateBox(dateToDisplay);
};

export const Header = ({ colors, date, name, type }) => {
  const { headerBackground, headerHeadline, categoryTitle } = colors;

  return (
    <Box w="100%" p="1" bg={headerBackground}>
      <Flex justifyContent="space-between" alignContent="center">
        <Box>
          <Text
            textAlign="left"
            fontSize="12px"
            fontWeight="700"
            color="white"
          >{`${getDate(date)}`}</Text>
        </Box>
        <Box textAlign="right" p="15px">
          <Heading
            as="h2"
            fontSize="36px"
            fontWeight="bold"
            mb="2"
            color={headerHeadline}
            fontStyle="italic"
          >
            {name}
          </Heading>
          <Heading
            as="h4"
            fontSize="22px"
            color={categoryTitle}
            textTransform="capitalize"
            fontStyle="italic"
          >
            {type}
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
};
