import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Link as ChakraLink,
  Flex,
  Box,
  VStack,
  Image,
  Text,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import { MdEmojiFoodBeverage } from 'react-icons/md';
import { FaBoxesStacked } from 'react-icons/fa6';
import {
  NewsIcon,
  FinanceIcon,
  // EmployeesIcon,
  // GoalsIcon,
  // TheaterIcon,
} from 'helpers/customIcons';
import { useUser } from 'routes/UserProvider';

const ALLOWED_COMPANIES = ['0001', '0010', '0015'];

const Link = ({
  children,
  icon,
  isASublink = false,
  disabled = false,
  ...props
}) => (
  <ChakraLink
    as={NavLink}
    w="100%"
    fontWeight="600"
    mt={isASublink && '2px'}
    p={isASublink ? '0 5px' : '5px'}
    textDecoration="none"
    color={disabled ? '#d6d6d6' : '#495b66'}
    pointerEvents={disabled ? 'none' : 'all'}
    _hover={{ color: '#67757e', bg: '#e0e0e05c' }}
    _focus={{ color: '#67757e', bg: '#e0e0e05c' }}
    _activeLink={{
      borderLeft: !isASublink && '4px solid #503963',
      color: '#67757e',
      bg: '#bdbdc194',
    }}
    {...props}
  >
    <Flex>
      {icon && <Icon as={icon} boxSize="23px" mr="10px" alignSelf="center" />}

      {children}
    </Flex>
  </ChakraLink>
);

export const Navbar = () => {
  const { user } = useUser();

  return (
    <Box zIndex="2" pt="20px">
      <Flex
        width="100%"
        height="150px"
        backgroundColor="#fff"
        borderRadius="30px"
        padding="10px 30px"
        alignItems="center"
        justifyContent="center"
        boxShadow="md"
      >
        {user?.company?.logo ? (
          <Image src={user?.company?.logo} />
        ) : (
          <Spinner size="lg" />
        )}
      </Flex>
      <VStack
        width="100%"
        mt="20px"
        spacing="16px"
        backgroundColor="#fff"
        borderRadius="30px"
        padding="50px 20px"
        boxShadow="md"
      >
        <Link to="/nachrichten" icon={NewsIcon}>
          <Text as="p">Nachrichten</Text>
        </Link>
        <Flex flexDirection="column" w="100%">
          <Link to="/finanzen" icon={FinanceIcon}>
            <Text as="p">Finanzen</Text>
          </Link>
          <Flex flexDirection="column" w="100%" pl="40px">
            <Link to="/finanzen/einnahmen" isASublink>
              <Flex>
                <Text as="p">Einnahmen</Text>
              </Flex>
            </Link>
            <Link to="/finanzen/ausgaben" isASublink>
              <Flex>
                <Text as="p">Ausgaben</Text>
              </Flex>
            </Link>
          </Flex>
        </Flex>
        {ALLOWED_COMPANIES.includes(user?.company?.rootFolder) && (
          <Link to="/cashier" icon={MdEmojiFoodBeverage}>
            <Text as="p">Cashier</Text>
          </Link>
        )}
        {/* <Link to="/benchmarking" icon={MdOutlineAutoGraph}>
          <Text as="p">Benchmarking</Text>
        </Link> */}
        <Link to="/pickups" icon={FaBoxesStacked}>
          <Text as="p">Pickups</Text>
        </Link>
        {/* <Link to="/mitarbeiter" icon={EmployeesIcon} disabled>
          <Text as="p">Mitarbeiter</Text>
        </Link>
        <Link to="/ziele" icon={GoalsIcon} disabled>
          <Text as="p">Ziele</Text>
        </Link>
        <Link to="/szenarios" icon={TheaterIcon} disabled>
          <Text as="p">Szenarios</Text>
        </Link> */}
      </VStack>
    </Box>
  );
};
