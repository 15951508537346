import { useState, useEffect, useCallback } from 'react';
import { useUser } from 'routes/UserProvider';
import { fetchCompanyById, updateCompany, createGoal } from '../services/api';

export const useCompanySettings = () => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState({});
  const [modifiedData, setModifiedData] = useState({});
  const currentYear = new Date().getFullYear();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchCompanyById(user?.company?._id);
      if (Object.keys(data).length > 0) {
        setInitialData(data);
        setModifiedData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.company?._id]);

  const getDifferences = (obj1, obj2) => {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return obj2;
      }
      return undefined; // Retornar undefined en lugar de un objeto vacío
    }

    if (
      obj1 !== null &&
      typeof obj1 === 'object' &&
      obj2 !== null &&
      typeof obj2 === 'object'
    ) {
      const differences = {};
      const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

      for (const key of allKeys) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        const nestedDiff = getDifferences(val1, val2);

        if (nestedDiff !== undefined) {
          differences[key] = nestedDiff;
        }
      }

      if (Object.keys(differences).length === 0) {
        return undefined; // Si no hay diferencias, regresa undefined
      }
      return differences;
    }

    if (obj1 !== obj2) {
      return obj2;
    }

    return undefined; // Regresar undefined en lugar de un objeto vacío
  };

  const addGoal = async goal => {
    try {
      const { data } = await createGoal({
        company: initialData._id,
        ...goal,
      });

      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const differences = getDifferences(initialData, modifiedData);

    if (differences) {
      const goals = differences?.configuration?.revenueGoals?.goals;
      const goalsIds = [];

      if (goals) {
        if (Object.keys(goals).length > 0) {
          for (let goal of goals) {
            if (!goal?._id) {
              const createdGoal = await addGoal(goal);
              goalsIds.push(createdGoal._id);
            } else {
              goalsIds.push(goal._id);
            }
          }
        }
      }

      try {
        let newData = { ...differences };
        if (goalsIds.length > 0) {
          newData = {
            ...differences,
            configuration: {
              ...differences.configuration,
              revenueGoals: {
                ...differences.configuration.revenueGoals,
                goals: goalsIds,
              },
            },
          };
        }

        const { data } = await updateCompany(initialData._id, newData);

        if (Object.keys(data).length > 0) {
          setInitialData(data);
          setModifiedData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (user?.company) {
      fetchData();
    }
  }, [fetchData, user]);

  return {
    initialData,
    modifiedData,
    setModifiedData,
    isLoading,
    currentYear,
    handleSubmit,
  };
};
