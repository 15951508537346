import { FormattedMessage } from 'react-intl';
import {
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ProfileSettings } from './components/ProfileSettings';
import { CompanySettings } from './components/CompanySettings';
import { SecuritySettings } from './components/SecuritySettings';

export const SettingsPage = () => {
  return (
    <Card mb="20px" mt={5}>
      <CardBody>
        <Tabs isLazy size="lg" variant="enclosed" colorScheme="whispersPrimary">
          <TabList mb={5}>
            {/* <Tab>Profile</Tab> */}
            <Tab>
              <FormattedMessage id="SETTINGS_PAGE_COMPANY_TAB_TITLE" />
            </Tab>
            {/* <Tab>Security</Tab> */}
          </TabList>
          <TabPanels>
            {/* <TabPanel>
              <ProfileSettings />
            </TabPanel> */}
            <TabPanel>
              <CompanySettings />
            </TabPanel>
            {/* <TabPanel>
              <SecuritySettings />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};
