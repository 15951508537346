import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'routes/UserProvider';
import {
  Box,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { Filters } from 'components/Filters';
import { useCashierPage } from './hooks/useCashierPage';
import { SortButton } from 'components/SortButton';

const formatValueToCurrency = value =>
  value.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const showWithColor = value => {
  if (!value) {
    return null;
  }

  let valueColor = 'black';

  const stringValue = typeof value === 'number' ? value.toString() : value;

  if ((typeof value === 'number' && value > 0) || stringValue.includes('+')) {
    valueColor = 'green.400';
  }

  if ((typeof value === 'number' && value < 0) || stringValue.includes('-')) {
    valueColor = 'red.400';
  }

  return (
    <Text as="span" color={valueColor}>
      {value}
    </Text>
  );
};

const showValue = (value, yoyGrowthPct, yoyGrowthVal) => {
  const formattedValue = formatValueToCurrency(value);
  const yoyGrowthValFormatted = yoyGrowthVal
    ? formatValueToCurrency(yoyGrowthVal)
    : null;
  const yoyGrowthValDisplay = yoyGrowthValFormatted
    ? `${yoyGrowthVal > 0 ? '+' : ''}${yoyGrowthValFormatted}`
    : '';

  return (
    <Text>
      {formattedValue}
      <Text as="span" ml="5px">
        ({yoyGrowthPct ? showWithColor(yoyGrowthPct) : '-'}
        {yoyGrowthValDisplay && ' | '}
        {showWithColor(yoyGrowthValDisplay)})
      </Text>
    </Text>
  );
};

const showDataRow = (key, name, totalRevenue, totalRevenueYtd) => {
  return (
    <Tr key={key && key}>
      <Td>
        <Text fontWeight="bold">{name || 'Others'}</Text>
      </Td>
      <Td isNumeric>
        {showValue(totalRevenue.value, totalRevenue.yoyGrowthPct)}
      </Td>
      <Td isNumeric>
        {showValue(
          totalRevenue.morning.value,
          totalRevenue.morning.yoyGrowthPct
        )}
      </Td>
      <Td isNumeric>
        {showValue(
          totalRevenue.afternoon.value,
          totalRevenue.afternoon.yoyGrowthPct
        )}
      </Td>
      <Td isNumeric>
        {showValue(
          totalRevenue.evening.value,
          totalRevenue.evening.yoyGrowthPct
        )}
      </Td>
      <Td isNumeric>
        {showValue(
          totalRevenueYtd.value,
          totalRevenueYtd.yoyGrowthPct,
          totalRevenueYtd.yoyGrowthVal
        )}
      </Td>
    </Tr>
  );
};

const showDataFirstRow = (key, name, totalRevenue, totalRevenueYtd) => (
  <Fragment key={key}>
    {showDataRow(null, name, totalRevenue, totalRevenueYtd)}
    <Tr>
      <Td colSpan="6" textAlign="center" py={6}>
        <Divider borderColor="whispersSecondary.500" />
      </Td>
    </Tr>
  </Fragment>
);

const ALLOWED_COMPANIES = ['0001', '0010', '0015'];

export const CashierPage = () => {
  const { data, isLoading, setQuery, query, filtersConfig } = useCashierPage();

  const navigate = useNavigate();
  const { user } = useUser();
  const company = user.company;

  useEffect(() => {
    if (!ALLOWED_COMPANIES.includes(company?.rootFolder)) {
      navigate('/');
    }
  }, [navigate, company]);

  return (
    <Box my="20px" mr="20px">
      <Filters config={filtersConfig} />
      <TableContainer>
        <Table variant="striped" colorScheme="whispersSecondary" size="lg">
          <Thead bgColor="whispersSecondary.500">
            <Tr>
              <Th color="white">
                <Text as="span">cashier</Text>
                <SortButton
                  colorScheme="whispersSecondary"
                  setSorting={setQuery}
                  sorting={query.sorting}
                  sortValue="name"
                  type="string"
                />
              </Th>
              <Th isNumeric color="white" colSpan={1} textAlign="center">
                <Text as="span" ml="5px">
                  total revenue
                </Text>
                <SortButton
                  colorScheme="whispersSecondary"
                  setSorting={setQuery}
                  sorting={query.sorting}
                  sortValue="totalRevenue.value"
                  type="number"
                />
              </Th>
              <Th isNumeric color="white" fontSize={12} fontStyle="italic">
                Morning
              </Th>
              <Th isNumeric color="white" fontSize={12} fontStyle="italic">
                Afternoon
              </Th>
              <Th isNumeric color="white" fontSize={12} fontStyle="italic">
                Evening
              </Th>
              <Th color="white" isNumeric>
                <Text as="span" ml="5px">
                  total revenue YTD
                </Text>
                <SortButton
                  colorScheme="whispersSecondary"
                  setSorting={setQuery}
                  sorting={query.sorting}
                  sortValue="totalRevenueYtd.value"
                  type="number"
                />
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Tbody h="300px">
              <Tr>
                <Td colSpan="6">
                  <Center>
                    <Spinner size="lg" />
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          ) : data?.rows?.length > 0 ? (
            <Tbody>
              <Tr></Tr>
              <Tr>
                <Td colSpan="6" py={2}></Td>
              </Tr>
              {data.rows.map(
                ({ _id, name, totalRevenue, totalRevenueYtd }, index) => {
                  if (index === 0 && name === 'Total') {
                    return showDataFirstRow(
                      _id,
                      name,
                      totalRevenue,
                      totalRevenueYtd
                    );
                  } else {
                    return showDataRow(
                      _id,
                      name,
                      totalRevenue,
                      totalRevenueYtd
                    );
                  }
                }
              )}
            </Tbody>
          ) : (
            <Tbody h="300px">
              <Tr>
                <Td colSpan="6">
                  <Center>
                    <Text>No data found</Text>
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
