import React, { useRef } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { Header } from './components/Header';
import { Lightbox } from './components/Lightbox';
import { Chart } from 'components/Chart';
import { COLORS, DEFAULT_COLORS } from './constants';
import { device } from 'helpers/responsive';

const SHAPES = {
  A1: [
    { gridArea: [1, 2, 1, 2] },
    { gridArea: [1, 1, 1, 1] },
    { gridArea: [2, 1, 2, 1] },
    { gridArea: [2, 2, 2, 2] },
  ],
  A2: [
    { gridArea: [1, 1, 1, 2] },
    { gridArea: [2, 1, 2, 1] },
    { gridArea: [2, 2, 2, 2] },
  ],
  A3: [
    { gridArea: [1, 2, 2, 2] },
    { gridArea: [1, 1, 1, 1] },
    { gridArea: [2, 1, 2, 1] },
  ],
  A4: [
    { gridArea: [1, 2, 1, 2] },
    { gridArea: [1, 1, 2, 1] },
    { gridArea: [2, 2, 2, 2] },
  ],
  A5: [
    { gridArea: [1, 2, 1, 2] },
    { gridArea: [1, 1, 1, 1] },
    { gridArea: [2, 1, 2, 2] },
  ],
  A6: [{ gridArea: [1, 1, 1, 2] }, { gridArea: [2, 1, 2, 2] }],
  A7: [{ gridArea: [1, 2, 2, 2] }, { gridArea: [1, 1, 2, 1] }],
  A8: [{ gridArea: [1, 1, 2, 2] }],
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-bottom-left-radius: 60px;
  border-top-right-radius: 60px;
  overflow: hidden;
  width: 'max-content';
  margin-bottom: 20px;
  /* @media ${device.laptopL} {
    border-bottom-left-radius: 57px;
  } */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const Container = styled.div`
  /* width: 1200px; */
  display: grid;
  grid-template-columns: [col1-start] 1fr [col1-end col2-start] 1fr [col2-end];
  grid-template-rows: [row1-start] 230px [row1-end row2-start] 230px [row2-start];
  gap: 4px;
  /* background-color: #fff; */
  /* width: fit-content; */

  /* @media ${device.laptopL} {
    display: grid;
    grid-template-columns: [col1-start] 314px [col1-end col2-start] 314px [col2-end];
    grid-template-rows: [row1-start] 166px [row1-end row2-start] 166px [row2-start];
    gap: 3px;
  } */
`;

const BoxArea = styled.div`
  grid-area: ${props => props.gridArea};
  grid-area: ${({ gridArea }) =>
    `row${gridArea[0]}-start / col${gridArea[1]}-start / row${gridArea[2]}-end / col${gridArea[3]}-end`};
  background: ${props => props.bodyBackground};
  text-align: center;
  /* padding: 20px 10px; */
  button {
    border: none;
    background-color: transparent;
  }
  // div > * {
  //   background-color: #fff;
  // }

  /* @media ${device.laptopL} {
    padding: 13px 10px;
  } */
`;

const showDescription = description => {
  if (typeof description === 'object') {
    return (
      <Box>
        <Text
          fontSize="md"
          maxW="100%"
          maxH="48px"
          overflow="hidden"
          color={description.color}
        >
          {description.text}
        </Text>
      </Box>
    );
  } else {
    return (
      <Box>
        <Text fontSize="md" maxW="100%" maxH="48px" overflow="hidden">
          {description}
        </Text>
      </Box>
    );
  }
};

export const BentoBox = ({ id, shape, date, title, type, boxes }) => {
  const boxRef = useRef(null);

  const boxShape = SHAPES[shape];
  const colors =
    COLORS[type.toLowerCase()] !== undefined
      ? COLORS[type.toLowerCase()]
      : DEFAULT_COLORS;

  return (
    <Wrapper id={id}>
      <Header colors={colors} date={date} name={title} type={type} />
      <Container shape={boxShape}>
        {boxes.map(({ title, value, description, location, info }, index) => (
          <BoxArea
            key={`${index}-${shape}`}
            ref={boxRef}
            gridArea={boxShape[location - 1].gridArea}
            bodyBackground={colors.bodyBackground}
          >
            <Box w="100%" h="100%" px="2" py="6">
              <Flex direction="column" h="100%" justifyContent="space-between">
                {title && (
                  <Flex justifyContent="center">
                    <Heading
                      as="h3"
                      fontSize="md"
                      fontWeight="900"
                      maxW="100%"
                      maxH="48px"
                      overflow="hidden"
                      color={colors.bodyHeadline}
                      textTransform="uppercase"
                      letterSpacing="3px"
                    >
                      {title}
                    </Heading>
                    {info && <Lightbox url={info} />}
                  </Flex>
                )}

                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {typeof value === 'object' ? (
                    <Chart option={value} showSeriesLabel={false} />
                  ) : (
                    <Heading
                      as="h3"
                      fontSize="50px"
                      fontWeight="300"
                      textAlign="center"
                      textTransform="uppercase"
                    >
                      {value}
                    </Heading>
                  )}
                </Box>
                {description && showDescription(description)}
              </Flex>
            </Box>
          </BoxArea>
        ))}
      </Container>
    </Wrapper>
  );
};
