import {
  Flex,
  Box,
  Center,
  Spinner,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  CardBody,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { valueColor } from 'helpers/valueColor';

const BG = '#dcebeb';
const COLOR = '#233d3d';

export const RevenuesTable = ({ data, isLoading, setQuery, query }) => (
  <TableContainer mt="20px" overflowY="auto" pb="20px">
    <Table variant="simple" colorScheme="blackAlpha" textAlign="center">
      <Thead bg={BG}>
        <Tr>
          <Th w="300px" textAlign="center" fontSize="15px" color={COLOR}>
            <Text as="span" color={COLOR}>
              Einnahmen
            </Text>
          </Th>

          <Th textAlign="center" fontSize="15px" color={COLOR}>
            <Flex justifyContent="center" alignItems="center">
              <Tooltip label="An diesem Tag bzw. in diesem Zeitraum generierter und verbuchter Umsatz (aber nicht zwingend in Rechnung gestellt).">
                <Text as="span" cursor="pointer">
                  gebuchter Umsatz
                </Text>
              </Tooltip>
            </Flex>
          </Th>

          <Th textAlign="center" fontSize="15px" color={COLOR}>
            <Flex justifyContent="center" alignItems="center">
              <Tooltip label="An diesem Tag bzw. in diesem Zeitraum in Rechnung gestellter Umsatz (laut Rechnungsdatum).">
                <Text as="span" cursor="pointer">
                  verrechneter Umsatz
                </Text>
              </Tooltip>
            </Flex>
          </Th>
          <Th textAlign="center" fontSize="15px" color={COLOR}>
            <Text as="span">Vorjahr</Text>
          </Th>
          <Th textAlign="center" fontSize="15px" color={COLOR}>
            <Text as="span">Ziel</Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {isLoading ? (
          <Tr>
            <Td colSpan="5" h="300px">
              <Center>
                <Spinner size="lg" />
              </Center>
            </Td>
          </Tr>
        ) : data ? (
          data?.rows.map(
            ({ name, parent, whispers, checkouts, lastYear, ziel }, index) => (
              <Tr key={index}>
                <Td>
                  <Card borderRadius="15px" bg={BG} ml={parent && '40px'}>
                    <CardBody>
                      <Text textAlign="center" fontWeight={!parent && 'bold'}>
                        {name}
                      </Text>
                    </CardBody>
                  </Card>
                </Td>

                <Td textAlign="center">{whispers}</Td>
                <Td textAlign="center">{checkouts}</Td>
                <Td textAlign="center">
                  <Box position="relative" w="fit-content" margin="0 auto">
                    <Text>{lastYear.value}</Text>
                    {lastYear?.percent?.value && (
                      <Box position="absolute" right="0">
                        <Text
                          fontSize="12px"
                          color={valueColor(lastYear?.percent?.color)}
                        >
                          {lastYear?.percent?.value}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Td>
                <Td textAlign="center">
                  <Box position="relative" w="fit-content" margin="0 auto">
                    <Text>{ziel.value}</Text>
                    {ziel?.percent?.value && (
                      <Box position="absolute" right="0">
                        <Text
                          fontSize="12px"
                          color={valueColor(ziel?.percent?.color)}
                        >
                          {ziel?.percent?.value}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Td>
              </Tr>
            )
          )
        ) : (
          <Tr>
            <Td colSpan="5" h="300px">
              <Center>
                <Text>Keine Daten vorhanden</Text>
              </Center>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  </TableContainer>
);
