import { useState } from 'react';
import { isObjectLike } from 'lodash';
import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Collapse,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import {
  DragHandleIcon,
  CloseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';
import { Draggable } from 'react-beautiful-dnd';
import { Chart } from 'components/Chart';

const getItemStyle = (_, draggableStyle) => ({
  width: '100%',
  ...draggableStyle,
});

export const DraggableItem = ({
  index,
  onCollapseWidget,
  onRemoveWidget,
  widget,
}) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: !widget.collapse,
  });

  const onAnimationComplete = definition => {
    if (isObjectLike(definition)) {
      setAnimationComplete(true);
    }
    if (definition === 'exit') {
      setAnimationComplete(false);
    }
  };

  const onCollapse = () => {
    onCollapseWidget(widget._id);
    onToggle();
  };

  return (
    <Draggable draggableId={widget._id} index={index}>
      {(provided, snapshot) => (
        <Box
          className="draggable-item"
          bg="white"
          p="5px 10px"
          borderRadius="5px"
          shadow={'md'}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Flex justifyContent={'space-between'} mb="5px">
            <Box {...provided.dragHandleProps}>
              <Tooltip label="Drag to reorder">
                <DragHandleIcon color="#A5A8A9" />
              </Tooltip>
              <Tooltip label={isOpen ? 'Collapse' : 'Expand'}>
                <Icon
                  ml={1}
                  as={isOpen ? ChevronUpIcon : ChevronDownIcon}
                  boxSize={6}
                  color="#A5A8A9"
                  cursor="pointer"
                  onClick={() => onCollapse(widget._id)}
                />
              </Tooltip>
            </Box>
            <Text>{widget.title}</Text>
            <Box>
              <Tooltip label="Remove">
                <CloseIcon
                  color="#A5A8A9"
                  cursor="pointer"
                  onClick={() => onRemoveWidget(widget._id)}
                />
              </Tooltip>
            </Box>
          </Flex>
          <Collapse
            in={isOpen}
            animateOpacity
            onAnimationComplete={onAnimationComplete}
          >
            <Box w="100%" h="300px">
              <Chart
                key={widget.id}
                option={widget}
                onAnimationComplete={animationComplete}
              />
            </Box>
          </Collapse>
        </Box>
      )}
    </Draggable>
  );
};
