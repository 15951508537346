import { Box, Divider } from '@chakra-ui/react';
import { Filters } from 'components/Filters';
import { PickupsChart } from './components/PickupsChart';
import { PickupsTable } from './components/PickupsTable';
import { usePickups } from './hooks/usePickups';

export const PickupsPage = () => {
  const { chart, pickups, isLoading, tableFiltersConfig, chartFiltersConfig } =
    usePickups();

  return (
    <Box my="20px" mr="20px">
      <Filters config={tableFiltersConfig} />
      <PickupsTable isLoading={isLoading} pickups={pickups} />
      <Divider my="5" />
      <Filters config={chartFiltersConfig} />
      <Box h="700px">
        <PickupsChart chart={chart} isLoading={isLoading} />
      </Box>
    </Box>
  );
};
