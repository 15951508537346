import { Box, Center, Spinner } from '@chakra-ui/react';
import { Chart } from 'components/Chart';

export const PickupsChart = ({ chart, isLoading }) => {
  return (
    <>
      <Box>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Chart optionV2={chart} style={{ width: '100%', height: '500px' }} />
        )}
      </Box>
    </>
  );
};
