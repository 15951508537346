import { Flex, Box, Text, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

/**
 * changeMonth: ƒ (e)
 * changeYear: ƒ (e)
 * customHeaderCount: 2
 * date: Fri May 19 2023 20:48:57 GMT+0200 (hora de verano de Europa central) {}
 * decreaseMonth: ƒ ()
 * decreaseYear: ƒ ()
 * increaseMonth: ƒ ()
 * increaseYear: ƒ ()
 * monthContainer: null
 * monthDate: Fri May 19 2023 20:48:57 GMT+0200 (hora de verano de Europa central) {}
 * nextMonthButtonDisabled: false
 * nextYearButtonDisabled: false
 * prevMonthButtonDisabled: false
 * prevYearButtonDisabled: false
 * selectingDate: null
 */
export const ExpendituresCustomHeader = ({
  date,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}) => {
  return (
    <Box>
      <Flex alignItems="center" h="32px">
        <Box w="40px" textAlign="right">
          <IconButton
            aria-label="Left month"
            variant="outline"
            colorScheme="gray"
            color="gray"
            icon={<ChevronLeftIcon />}
            borderRadius="50%"
            fontSize="30px"
            size="sm"
            onClick={decreaseYear}
            disabled={prevYearButtonDisabled}
          />
        </Box>
        <Box flex="1" textAlign="center">
          <Text fontWeight="bold">
            {date.toLocaleString('de-DE', {
              year: 'numeric',
            })}
          </Text>
        </Box>
        <Box w="40px" textAlign="left">
          <IconButton
            aria-label="Right month"
            variant="outline"
            colorScheme="gray"
            color="gray"
            icon={<ChevronRightIcon />}
            borderRadius="50%"
            fontSize="30px"
            size="sm"
            onClick={increaseYear}
            disabled={nextYearButtonDisabled}
          />
        </Box>
      </Flex>
    </Box>
  );
};
