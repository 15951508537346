import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Flex, Progress, List, ListItem, Badge } from '@chakra-ui/react';
import { WarningIcon, CheckCircleIcon } from '@chakra-ui/icons';

const CRITERIA = formatMessage => [
  {
    regex: /.{8,}/,
    label: formatMessage({
      id: 'CHANGE_PASSWORD_PAGE_FORM_PASSWORD_CRITERIA_8_CHARACTERS',
    }),
    isMandatory: true,
  },
  {
    regex: /[A-Z]/,
    label: formatMessage({
      id: 'CHANGE_PASSWORD_PAGE_FORM_PASSWORD_CRITERIA_UPPERCASE_LETTER',
    }),
  },
  {
    regex: /[a-z]/,
    label: formatMessage({
      id: 'CHANGE_PASSWORD_PAGE_FORM_PASSWORD_CRITERIA_LOWERCASE_LETTER',
    }),
  },
  {
    regex: /[0-9]/,
    label: formatMessage({
      id: 'CHANGE_PASSWORD_PAGE_FORM_PASSWORD_CRITERIA_NUMBER',
    }),
  },
  {
    regex: /[_!@$&]/,
    label: formatMessage(
      {
        id: 'CHANGE_PASSWORD_PAGE_FORM_PASSWORD_CRITERIA_SPECIAL_CHARACTER',
      },
      { characters: <Badge ml={2}>_ ! @ $ &</Badge> }
    ),
  },
];

const getProgressBarColor = strength => {
  if (strength <= 1) return 'red';
  if (strength <= 3) return 'yellow';
  return 'green';
};

export const PasswordStrength = ({ password, onValidityChange }) => {
  const { formatMessage } = useIntl();
  const [strength, setStrength] = useState(0);

  useEffect(() => {
    const newStrength = CRITERIA(formatMessage).reduce((acc, curr) => {
      if (curr.regex.test(password)) {
        return acc + 1;
      }
      return acc;
    }, 0);

    if (onValidityChange) {
      const allMandatoryMet = CRITERIA(formatMessage).every(criteria => {
        return !criteria.isMandatory || criteria.regex.test(password);
      });

      onValidityChange(allMandatoryMet);
    }
    setStrength(newStrength);
  }, [password, onValidityChange, formatMessage]);

  const showIcon = matches => {
    if (matches) {
      return <CheckCircleIcon mr={2} color="green.500" />;
    } else {
      return <WarningIcon mr={2} color="yellow.500" />;
    }
  };

  return (
    <>
      <Progress
        value={(strength / CRITERIA(formatMessage).length) * 100}
        colorScheme={getProgressBarColor(strength)}
        borderRadius="md"
        size="sm"
      />
      <List spacing={3} mt={3}>
        {CRITERIA(formatMessage).map(({ regex, label, isMandatory }, index) => (
          <ListItem key={index} as={Flex} alignItems="center">
            {showIcon(regex.test(password))}
            {label}
            {isMandatory && (
              <Badge colorScheme="red" ml={2}>
                Mandatory
              </Badge>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
};
