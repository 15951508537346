import { useCallback } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorageToken } from './hooks/useLocalStorageToken';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { auth, login, logout } = context;

  return {
    auth,
    login,
    logout,
  };
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useLocalStorageToken();
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(
    async token => {
      setAuth(token);

      return navigate('/nachrichten');
    },
    [navigate, setAuth]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setAuth(null);

    navigate('/', { replace: true });
  }, [navigate, setAuth]);

  const value = useMemo(() => ({ auth, login, logout }), [auth, login, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
