import { useState, useEffect } from 'react';
import { fetchPickups } from '../services/api';
import { createChartOption } from '../utils/chartConfig';

const formatDate = date => {
  return date.toISOString().slice(0, 10);
};

const getDateArray = () => {
  const today = new Date();

  // Crear una nueva fecha para 6 meses antes
  const sixMonthsBefore = new Date(today);
  sixMonthsBefore.setMonth(today.getMonth() - 6);

  // Crear una nueva fecha para 6 meses después
  const sixMonthsAfter = new Date(today);
  sixMonthsAfter.setMonth(today.getMonth() + 6);

  // Formatear todas las fechas y devolverlas en un array
  return [
    formatDate(sixMonthsBefore),
    formatDate(today),
    formatDate(sixMonthsAfter),
  ];
};

export const usePickups = () => {
  const [previous6Months, todayDateString, next6Months] = getDateArray();

  const [query, setQuery] = useState({
    tsd: previous6Months,
    ted: todayDateString,
    csd: todayDateString,
    ced: next6Months,
    period: 'day',
  });
  const [pickups, setPickups] = useState(null);
  const [chart, setChart] = useState(null);
  const [enabledDates, setEnabledDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tableFiltersConfig = [
    {
      type: 'Date-Range-Filter-V1',
      setDates: dates =>
        setQuery(prevData => ({
          ...prevData,
          tsd: dates.startDate,
          ted: dates.endDate,
        })),
      dates: {
        startDate: query.tsd,
        endDate: query.ted,
      },
    },
  ];

  const chartFiltersConfig = [
    {
      type: 'Period-Filter-V1',
      options: [
        { label: 'TAG', value: 'day' },
        { label: 'WOCHE', value: 'week' },
        { label: 'MONAT', value: 'month' },
      ],
      setPeriod: period => setQuery(prevData => ({ ...prevData, period })),
      period: query.period,
    },
    {
      type: 'Date-Range-Filter-V1',
      setDates: dates =>
        setQuery(prevData => ({
          ...prevData,
          csd: dates.startDate,
          ced: dates.endDate,
        })),
      dates: {
        startDate: query.csd,
        endDate: query.ced,
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchPickups(query);
        setPickups(data?.pickups);
        setChart(createChartOption(data?.chart));
        setEnabledDates(data?.enabledDates);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [query]);

  return {
    chart,
    pickups,
    enabledDates,
    isLoading,
    setQuery,
    query,
    tableFiltersConfig,
    chartFiltersConfig,
  };
};
