import {
  Flex,
  Box,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { PeriodFilter } from './components/PeriodFilter';
import { DateFilter } from './components/DateFilter';
import { DateRangeFilter } from './components/DateRangeFilter';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Filter = filter => {
  switch (filter.type) {
    case 'Period-Filter-V1':
      return <PeriodFilter {...filter} />;
    case 'Date-Filter-V1':
      return <DateFilter {...filter} />;
    case 'Date-Range-Filter-V1':
      return <DateRangeFilter {...filter} />;
    case 'Download-Filter-V1':
      return (
        <Box>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              fontWeight="bold"
              borderColor="#B3BABE"
              color="#495B66"
              variant="outline"
              borderRadius="15px"
              w="fit-content"
            >
              Export
            </MenuButton>
            <MenuList>
              <MenuItem>View (.pdf)</MenuItem>
              <MenuItem>All data (.csv)</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      );
    default:
      return null;
  }
};

export const Filters = ({ config = [] }) =>
  config.length ? (
    <Flex mb="20px" justifyContent="right">
      <HStack spacing="5px">
        {config.map((filter, index) => (
          <Filter key={index} {...filter} />
        ))}
      </HStack>
    </Flex>
  ) : null;
