import { Navigate, useOutlet } from 'react-router-dom';
import { Grid, GridItem, Container } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { UserProvider } from './UserProvider';
import { useAuth } from './AuthProvider';
import { Header } from 'components/Header';
import { Navbar } from 'components/Navbar';

export const ProtectedLayout = () => {
  const { auth } = useAuth();
  const outlet = useOutlet();

  if (isEmpty(auth)) {
    return <Navigate to="/login" />;
  }

  return (
    <UserProvider>
      <Grid
        templateAreas={`
          "header"
          "main"
      `}
        gridTemplateRows={'70px 1fr'}
        gridTemplateColumns={'1fr'}
        h="100vh"
      >
        <GridItem area={'header'}>
          <Header />
        </GridItem>
        <GridItem area={'main'}>
          <Container maxW="1700px" h="100%">
            <Grid
              templateAreas={`
              "navbar content"
            `}
              gridTemplateRows={'1fr'}
              gridTemplateColumns={'250px 1fr'}
              h="calc(100vh - 70px)"
              gap={{
                sm: '0px 10px',
                md: '0px 20px',
                lg: '0px 30px',
                xl: '0px 40px',
                '2xl': '0px 50px',
              }}
            >
              <GridItem pl="20px" area={'navbar'} position="sticky">
                <Navbar />
              </GridItem>
              <GridItem area={'content'} overflowY="auto">
                {outlet}
              </GridItem>
            </Grid>
          </Container>
        </GridItem>
      </Grid>
    </UserProvider>
  );
};
