import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Flex,
  Box,
  Center,
  Image,
  IconButton,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { FaBell, FaUser } from 'react-icons/fa';
import logo from './images/logo2.png';

const IconCircle = ({ children, ...props }) => (
  <Center
    w="30px"
    h="30px"
    bg="#dae2e7"
    borderRadius="50%"
    cursor="pointer"
    {...props}
  >
    {children}
  </Center>
);

export const Header = () => {
  return (
    <Box
      position="sticky"
      width="100%"
      height="100%"
      boxShadow="md"
      zIndex="10"
      bg="#fff"
    >
      <Container maxW="1700px" h="100%" width="100%" height="100%">
        <Flex
          justifyContent="space-between"
          position="relative"
          alignItems="center"
          width="100%"
          height="100%"
          px="20px"
        >
          <Link to="/">
            <Image src={logo} height="40px" width="auto" />
          </Link>
          <Flex>
            {/* <IconCircle mr="10px" position="relative">
          <Box
            bg="#9b5169"
            borderRadius="50%"
            px="2px"
            color="#fff"
            position="absolute"
            top="-5px"
            right="-5px"
          >
            <Text fontSize="12px" fontWeight="bold">
              12
            </Text>
          </Box>
          <Icon as={FaBell} w="16px" h="16px" color="#879297" />
        </IconCircle> */}
            {/* <IconCircle onClick={handleClick}>
          <Icon as={FaUser} w="16px" h="16px" color="#879297" />
        </IconCircle> */}
            <Menu closeOnSelect={true}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FaUser />}
                bg="#dae2e7"
                color="#879297"
                borderRadius={50}
                size="md"
              />
              <MenuList>
                <MenuItem as={Link} to="/settings">
                  <FormattedMessage id="SETTINGS_PAGE_LINK" />
                </MenuItem>
                <MenuItem as={Link} to="/logout">
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
