import { useNavigate, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';

const RESPONSE_MESSAGES = {
  success: {
    PASSWORD_CHANGED: {
      title: 'CHANGE_PASSWORD_PAGE_SUCCESS_ALERT_TITLE',
      description1: 'CHANGE_PASSWORD_PAGE_SUCCESS_ALERT_DESCRIPTION',
      description2: 'CHANGE_PASSWORD_PAGE_SUCCESS_TEXT',
    },
  },
  error: {
    EXPIRED_TOKEN: {
      title: 'GENERAL_ERROR_EXPIRED_TOKEN_TITLE',
      description1: 'GENERAL_ERROR_EXPIRED_TOKEN_DESCRIPTION',
      description2: 'CHANGE_PASSWORD_PAGE_ERROR_DESCRIPTION',
    },
    INVALID_TOKEN: {
      title: 'GENERAL_ERROR_INVALID_TOKEN_TITLE',
      description1: 'GENERAL_ERROR_INVALID_TOKEN_DESCRIPTION',
      description2: 'CHANGE_PASSWORD_PAGE_ERROR_DESCRIPTION',
    },
    FORBIDDEN: {
      title: 'GENERAL_ERROR_FORBIDDEN_TITLE',
      description1: 'GENERAL_ERROR_FORBIDDEN_DESCRIPTION',
      description2: 'GENERAL_ERROR_FORBIDDEN_DESCRIPTION_2',
    },
    INTERNAL_SERVER_ERROR: {
      title: 'GENERAL_ERROR_INTERNAL_SERVER_ERROR_TITLE',
      description1: 'GENERAL_ERROR_INTERNAL_SERVER_ERROR_DESCRIPTION',
      description2: '',
    },
  },
};

export const StatusAlert = ({ status, code }) => {
  const { title, description1, description2 } =
    RESPONSE_MESSAGES[status]?.[code] ||
    RESPONSE_MESSAGES.error?.INTERNAL_SERVER_ERROR;

  return (
    <Stack pt="20px" spacing={4}>
      <Alert
        status={status}
        variant="top-accent"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        borderRadius="15px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          <FormattedMessage id={title} />
        </AlertTitle>
        <AlertDescription maxWidth="lg">
          <FormattedMessage id={description1} />
        </AlertDescription>
      </Alert>
      {description2 && (
        <Text>
          <FormattedMessage id={description2} />
        </Text>
      )}
      {status === 'error' && (
        <Box>
          <Button
            as={Link}
            to="/login"
            size="lg"
            variant="outline"
            colorScheme="whispersPrimary"
            borderRadius="15px"
            loadingText="Anmelden"
          >
            <FormattedMessage id="CHANGE_PASSWORD_PAGE_ERROR_BUTTON" />
          </Button>
        </Box>
      )}
    </Stack>
  );
};
