import { Flex, Box, Heading, Text, HStack, IconButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ViewIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import { useFinance } from './hooks/useFinance';
import { Filters } from './components/Filters';
import { RevenuesTable } from './components/RevenuesTable';
import { ExpendituresTable } from './components/ExpendituresTable';

export const Finance = ({ type }) => {
  const { setQuery, isLoading, data, enabledDates, query } = useFinance({
    type,
  });
  const isExpendituresView = type === 'expenditures';

  return (
    <Box h="100%" mt="20px" mr="20px">
      <Filters
        data={data}
        enabledDates={enabledDates}
        isExpendituresView={isExpendituresView}
        isLoading={isLoading}
        setData={setQuery}
        query={query}
      />
      {isExpendituresView ? (
        <ExpendituresTable
          data={data}
          isLoading={isLoading}
          setQuery={setQuery}
          query={query}
        />
      ) : (
        <RevenuesTable
          data={data}
          isLoading={isLoading}
          setQuery={setQuery}
          query={query}
        />
      )}
      {!isLoading && !_.isEmpty(data?.kpis) && (
        <Box
          mt="20px"
          mb="20px"
          p="20px"
          border="1px"
          borderColor="gray.400"
          borderStyle="dashed"
          borderRadius="15px"
          position="relative"
        >
          <Heading
            as="h2"
            size="md"
            color={isExpendituresView ? '#622900' : '#233d3d'}
            position="absolute"
            top="-14px"
            left="20px"
            bg="#f1f1f3"
            px="20px"
          >
            KPI
          </Heading>
          <HStack spacing={4}>
            {data?.kpis.map(({ name, value }) => {
              const encodedName = encodeURIComponent(name);
              return (
                <Flex
                  key={name}
                  alignItems="center"
                  borderRadius="15px"
                  variant="solid"
                  bg="#dcebeb"
                  p="10px"
                >
                  <Text>{`${name}: ${value}`}</Text>
                  <Link to={`/benchmarking/${encodedName}`}>
                    <IconButton
                      icon={<ViewIcon />}
                      ml="5px"
                      variant="outline"
                      isRound
                    />
                  </Link>
                </Flex>
              );
            })}
          </HStack>
        </Box>
      )}
    </Box>
  );
};
